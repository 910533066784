import { isJson } from './utils';

/* TODO - Review these codes returned by service
    Should not provide details like valid user
    Only not matching user/pass */
const AUTH_WRONG_PASSWORD_CODE_ERROR = 'auth/wrong-password';
const AUTH_USER_NOT_FOUND_CODE_ERROR = 'auth/user-not-found';
const AUTH_INVALID_EMAIL_CODE_ERROR = 'auth/invalid-email';
const AUTH_TOO_MANY_REQUESTS_CODE_ERROR = 'auth/too-many-requests';
const AUTH_INVALID_ACTION_CODE_ERROR = 'auth/invalid-action-code';

const AUTH_CODE_ERRORS = {
  [AUTH_WRONG_PASSWORD_CODE_ERROR]: true,
  [AUTH_USER_NOT_FOUND_CODE_ERROR]: true,
  [AUTH_INVALID_EMAIL_CODE_ERROR]: true,
};

const AUTH_INVALID_LOGIN_CREDENTIALS_MESSAGE = 'INVALID_LOGIN_CREDENTIALS';

const isAuthCredentialsError = (error) => {
  const { code, message } = error;

  return (
    AUTH_CODE_ERRORS[code] ||
    (
      isJson(message) &&
      JSON.parse(message).error?.message === AUTH_INVALID_LOGIN_CREDENTIALS_MESSAGE
    )
  );
};

const isTooManyRequestsError = (error) => {
  const { code } = error;

  return code === AUTH_TOO_MANY_REQUESTS_CODE_ERROR;
};

const isInvalidActionError = (error) => {
  const { code } = error;

  return code === AUTH_INVALID_ACTION_CODE_ERROR;
};

const GENERIC_CODE_ERROR = 'TR-GC-001';
const NOT_FOUND_ERROR = 'TR-PE-004';
const REWARDS_FUNDS_CODE_ERROR = 'TR-PE-005';
const REWARDS_NO_MORE_CODES = 'TR-PE-006';
const REWARDS_NO_MORE_CODES_PER_USER = 'TR-PE-007';
const GIFT_CARDS_FUNDS_CODE_ERROR = 'TR-PE-020';
const TAKE_BACK_BAG_NOT_AUTHORIZED = 'TR-AU-001';

const errorCodes = {
  [GENERIC_CODE_ERROR]: 'Something went wrong. Please try again later or contact support.',
  [NOT_FOUND_ERROR]: 'Not found.',
  [REWARDS_FUNDS_CODE_ERROR]: 'Insufficient funds',
  [REWARDS_NO_MORE_CODES]: 'No more codes available for that reward.',
  [REWARDS_NO_MORE_CODES_PER_USER]: 'No more codes available for this user for this reward',
  [GIFT_CARDS_FUNDS_CODE_ERROR]: 'Insufficient funds',
  [TAKE_BACK_BAG_NOT_AUTHORIZED]: 'THE BAG CODE YOU ARE TRYING TO REGISTER WAS ALREADY REGISTERED BY ANOTHER ACCOUNT',
};

const getGenericError = () => errorCodes[GENERIC_CODE_ERROR];

export {
  errorCodes,
  getGenericError,
  isAuthCredentialsError,
  isTooManyRequestsError,
  isInvalidActionError,
  GENERIC_CODE_ERROR,
  NOT_FOUND_ERROR,
  REWARDS_FUNDS_CODE_ERROR,
  REWARDS_NO_MORE_CODES,
  REWARDS_NO_MORE_CODES_PER_USER,
  GIFT_CARDS_FUNDS_CODE_ERROR,
};
