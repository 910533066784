const RefreshSvg = ({ stroke = '#6F706F' }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={16}
    height={16}
    fill="none"
  >
    <g
      stroke={stroke}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.333}
    >
      <path d="M14 8a6 6 0 0 0-6-6 6.5 6.5 0 0 0-4.493 1.827L2 5.333" />
      <path d="M2 2v3.333h3.333M2 8a6 6 0 0 0 6 6 6.5 6.5 0 0 0 4.493-1.827L14 10.667" />
      <path d="M10.667 10.667H14V14" />
    </g>
  </svg>
);
export default RefreshSvg;
