import { useCallback, useEffect, useMemo, useState } from 'react';
import classNames from 'classnames';
import moment from 'moment';

import DotsLoader from '../../../../../global/DotsLoader';
import { fetchShippingProviderLocations } from '../../../../../utils/service';
import IconPin from '../../../../../images/icon-pin.svg';
import IconChevronUp from '../../../../../images/chevron-up.svg';
import IconChevronDown from '../../../../../images/chevron-down.svg';

import './ShippingProviderLocations.scss';
import { getAddressString } from '../../../../../utils/locations';

const getScheduleData = (openingHours) => {
  let description = 'Closed today';
  const list = [];

  // eslint-disable-next-line no-restricted-syntax
  for (const { id, dayOfWeek, openingTime, closingTime } of openingHours) {
    list.push(
      <tr key={id}>
        <td>{moment(openingTime).weekday(dayOfWeek).format('ddd')}</td>
        <td>{`${moment(openingTime).format('hA')}-${moment(closingTime).format('hA')}`}</td>
      </tr>,
    );

    if (moment().weekday() === dayOfWeek && moment().isBefore(closingTime)) {
      description = `Open today until ${moment(closingTime).format('hA')}`;
    }
  }

  return { description, list };
};

function ShippingProviderLocations({
  locations,
  address = {},
  logoRect,
  inDialog,
}) {
  const [shippingProviderLocations, setShippingProviderLocations] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

  const getLocations = useCallback(async (locationsData) => {
    setLoading(true);

    const {
      data,
      error: locationsError,
    } = await fetchShippingProviderLocations({
      ...locationsData,
      address: getAddressString(address),
      zipcode: address.zip,
    });

    if (locationsError) {
      setShippingProviderLocations([]);
      setError(locationsError?.message);
      setLoading(false);
      return;
    }

    setShippingProviderLocations(data);
    setError('');
    setLoading(false);
  }, []);

  useEffect(() => {
    if (locations?.enabled) {
      getLocations(locations);
    }
  }, [locations]);

  return (
    <div className={classNames('ShippingProviderLocations', { 'in-dialog': inDialog })}>
      {loading ? (
        <DotsLoader className="ShippingProviderLocations__loader" />
      ) : (
        error || (
          <>
            {logoRect && (
              <img
                className="ShippingProviderLocations__logo"
                src={logoRect}
                alt="shipping provider logo"
              />
            )}
            {shippingProviderLocations.length === 0 && (
              <p className="ShippingProviderLocations__desc">
                No locations found
              </p>
            )}
            {inDialog && shippingProviderLocations.length > 0 && (
              <p className="ShippingProviderLocations__desc">
                {locations?.description}
              </p>
            )}
            {shippingProviderLocations.map(shippingProviderLocation => (
              <LocationsItem shippingProviderLocation={shippingProviderLocation} />
            ))}
          </>
        )
      )}
    </div>
  );
}

const LocationsItem = ({ shippingProviderLocation }) => {
  const [collapsed, setCollapsed] = useState(true);

  const {
    id: locationId,
    name: locationName,
    address: locationAddress,
    openingHours: locationOpeningHours,
    distance: locationDistance,
    hoursMayVary: locationHoursMayVary,
  } = useMemo(() => shippingProviderLocation, [shippingProviderLocation]);

  const {
    description: scheduleDescription,
    list: scheduleList,
  } = useMemo(() => (
    getScheduleData(locationOpeningHours)
  ), [locationOpeningHours]);

  return (
    <div
      key={locationId}
      className="ShippingProviderLocationsItem"
    >
      <div className="ShippingProviderLocationsItem__desc">
        <h4>{locationName}</h4>
        <p>{locationAddress.address}</p>
        <p>{`${locationAddress.city}, ${locationAddress.state} ${locationAddress.zipcode}`}</p>
        <p className="ShippingProviderLocationsItem__desc-more">
          <span>{scheduleDescription}</span>
          <button
            type="button"
            className="ShippingProviderLocationsItem__desc-more-btn"
            onClick={() => setCollapsed(!collapsed)}
          >
            {`show ${collapsed ? 'more' : 'less'}`}
          </button>
          <img src={collapsed ? IconChevronDown : IconChevronUp} alt="show more icon" />
        </p>
        {scheduleList.length > 0 && (
          <div className={classNames('ShippingProviderLocationsItem__desc-schedule', { collapsed })}>
            <table>
              {scheduleList}
            </table>
            {locationHoursMayVary && (
              <p>Hours may vary during the holidays.</p>
            )}
          </div>
        )}
      </div>
      <div className="ShippingProviderLocationsItem__distance">
        <img src={IconPin} alt="location icon" />
        <p>{`${parseFloat(locationDistance).toFixed(2)} mi`}</p>
      </div>
    </div>
  );
};

export default ShippingProviderLocations;
