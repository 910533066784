import {
  TransactionActivityTypes,
  TransactionStatusTypes,
  TransactionType,
} from '../config/transactions';

const isPaidStatusType = (statusType) => (
  statusType &&
  statusType === TransactionStatusTypes.TRANSACTION_PAID
);

const getTransactionActivityType = ({ productName, id, typeId }) => {
  const foundProductName = [
    TransactionActivityTypes.TAKE_BACK_BAG,
    TransactionActivityTypes.TECH_TAKE_BACK_BOX,
  ].find(transactionActivityType => productName === transactionActivityType.id);
  if (foundProductName) {
    return foundProductName;
  }

  const foundActivityType = Object.values(TransactionActivityTypes)
    .find(transactionActivityType => id.includes(transactionActivityType.id));
  if (foundActivityType) {
    return foundActivityType;
  }

  if (typeId === TransactionType.REWARD) {
    return TransactionActivityTypes.PROMO;
  }

  return TransactionActivityTypes.DEFAULT;
};

export {
  isPaidStatusType,
  getTransactionActivityType,
};
