import './GenericBanner.scss';

const GenericBanner = ({
  content,
}) => {
  const {
    title,
    description,
    ctaLabel,
    ctaURL,
    srcImage,
  } = content;
  return (
    <div className="GenericBanner">
      <div className="GenericBanner__container">
        <div className="GenericBanner__title">
          {title}
        </div>
        <div className="GenericBanner__description">
          {description}
        </div>
        {ctaLabel && (
          <a
            className="GenericBanner__cta"
            href={ctaURL ?? '#'}
          >
            {ctaLabel}
          </a>
        )}
      </div>
      {srcImage && (
        <div className="GenericBanner__img">
          <img src={srcImage} alt="generic banner" />
        </div>
      )}
    </div>
  );
};

export default GenericBanner;
