import { useCallback, useMemo } from 'react';
import { useHistory } from 'react-router-dom';

import { BUTTON_SIZE, BUTTON_TYPE, Button } from '../../Button';
import TbbGrey from '../../../images/take-back-bags-gray.svg';
import TbbBoxDefault from '../../../images/tbb_white_front.webp';
import OldTbb from '../../../images/old-tbb.png';
import { formatMMDDYY } from '../../../utils/dates';
import { shippingProviderStatus, isExpiredLabel } from '../../../utils/easyPost';
import { isTechRecyclingProductType } from '../../../utils/tbbRegistration';
import { REGISTER_CODE_PARAM, ROUTE_TAKE_BACK_BAGS_DETAILS } from '../../../config/routes';

import style from './TbbItemRow.module.scss';

const {
  container,
  infoContainer,
  button,
  titleStyle,
  subtitleStyle,
  imageStyle,
  dataContainer,
  deliveredDateContainer,
  shippedDateContainer,
  shippedExpiredDateContainer,
  actionContainer,
  imageContainer,
  statusInfo,
} = style;

function getImageSrc(pin, recyclingProductTypeId) {
  if (pin) {
    return isTechRecyclingProductType(recyclingProductTypeId) ? TbbBoxDefault : TbbGrey;
  }
  return OldTbb;
}

const ItemRow = ({ itemData = {} }) => {
  const history = useHistory();

  const {
    code: redemptionCode,
    pin,
    redeemedAt,
    recyclingProductTypeId,
    shipment: {
      shipmentStatus,
      shippingLabelExpiresAt,
      shippedAt,
      deliveredAt,
      shippingMethod,
    } = {},
  } = useMemo(() => itemData, [itemData]);

  const isShipped = useMemo(() => (
    shipmentStatus === shippingProviderStatus.in_transit ||
    shipmentStatus === shippingProviderStatus.out_for_delivery
  ), [shipmentStatus]);

  const statusDateTag = useCallback(() => {
    if (shipmentStatus === shippingProviderStatus.delivered) {
      return (
        <div className={deliveredDateContainer}>
          Delivered:
          {' '}
          {formatMMDDYY(deliveredAt)}
        </div>
      );
    }

    if (isShipped) {
      return (
        <div className={shippedDateContainer}>
          Shipped:
          {' '}
          {formatMMDDYY(shippedAt)}
        </div>
      );
    }

    if (isExpiredLabel(shippingLabelExpiresAt)) {
      return (
        <div className={shippedExpiredDateContainer}>
          Label expired:
          {' '}
          {formatMMDDYY(shippingLabelExpiresAt)}
        </div>
      );
    }

    return (
      <div className={shippedDateContainer}>
        Ship by:
        {' '}
        {formatMMDDYY(shippingLabelExpiresAt)}
      </div>
    );
  }, [shipmentStatus, shippingLabelExpiresAt, shippedAt, deliveredAt]);

  return (
    <div className={container}>
      <div className={dataContainer}>
        <div className={imageContainer}>
          <img src={getImageSrc(pin, recyclingProductTypeId)} alt="tbb" className={imageStyle} />
        </div>
        <div className={actionContainer}>
          <div className={infoContainer}>
            <div className={titleStyle}>{`ID: ${itemData.id}`}</div>
            {pin && shipmentStatus !== shippingProviderStatus.delivered && !isShipped && (
              <div className={shippedDateContainer}>{`PIN: ${pin}`}</div>
            )}
            {redeemedAt && shipmentStatus !== shippingProviderStatus.delivered && !isShipped && (
              <div className={subtitleStyle}>{`Registered on: ${formatMMDDYY(redeemedAt)}`}</div>
            )}
            <div className={statusInfo}>
              {shippingMethod?.logoUrl && (
                <img src={shippingMethod?.logoUrl} alt="shipping provider logo" />
              )}
              {statusDateTag()}
            </div>
          </div>
        </div>
      </div>
      {shipmentStatus !== shippingProviderStatus.delivered && !isShipped && (
        <Button
          type={BUTTON_TYPE.SECONDARY}
          size={BUTTON_SIZE.LARGE}
          className={button}
          onClick={() => history.push(`${ROUTE_TAKE_BACK_BAGS_DETAILS}?${REGISTER_CODE_PARAM}=${redemptionCode}`)}
        >
          {isExpiredLabel(shippingLabelExpiresAt) ? 'CREATE NEW LABEL' : shippingMethod?.configuration?.myTbbCta}
        </Button>
      )}
    </div>
  );
};

export default ItemRow;
