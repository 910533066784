const getGiftCardRatio = (tcRatio) => {
  const [cash, tc] = (tcRatio ?? '1:1').split(':');
  return Number(cash) / Number(tc);
};

const getGiftCardBrandValues = ({
  giftCardBrand = {},
  currentBalance = 0,
}) => {
  const {
    tcRatio,
    denominations = [],
  } = giftCardBrand;

  const ratio = getGiftCardRatio(tcRatio);

  const minDenomination = denominations[0] ?? 0;
  const maxDenomination = denominations[denominations.length - 1] ?? 0;
  const minBalance = minDenomination / ratio;

  return {
    ratio,
    minDenomination,
    maxDenomination,
    minBalance,
    disabled: denominations?.length === 0 || currentBalance < minBalance,
  };
};

const getGiftCardUniqueUrl = ({ issuedGiftCard = {}, url = '', giftCardBrand = {} }) => (
  url || issuedGiftCard?.url || giftCardBrand?.website
);

const getGiftCardBrandDenominationOptions = (denominations = []) => (
  denominations.map(value => ({
    uid: value,
    rewardAmount: String(value),
    exchangeValue: value,
  }))
);

const getGiftCardDenominationsMessage = (denominations = []) => {
  if (denominations.length === 0) {
    return '';
  }

  if (denominations.length === 1) {
    return `Available at $${denominations[0]}`;
  }

  if (denominations.length <= 5) {
    return `Available at $${denominations.join(', $')}`;
  }

  return `Available at $${denominations.slice(0, 4).join(', $')}, ...$${denominations[denominations.length - 1]}`;
};

export {
  getGiftCardRatio,
  getGiftCardBrandValues,
  getGiftCardUniqueUrl,
  getGiftCardBrandDenominationOptions,
  getGiftCardDenominationsMessage,
};
