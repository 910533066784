import { formatMMDDYY } from '../../../utils/dates';
import { formatCurrencyNumber, getTransactionAmount, isNegativeTransaction } from '../../../utils/numbers';
import { getTransactionActivityType, isPaidStatusType } from '../../../utils/transactions';
import TrashieCoin from '../../../images/TC-icon.svg';

import styles from './ActivityListRow.module.scss';

const {
  main,
  container,
  rowContent,
  details,
  merchant,
  date,
  amount,
  negative,
  status,
  statusText,
  tcIcon,
  detailsTitle,
  detailsContainer,
  amountContainer,
} = styles;

const DATA_NOT_AVAILABLE = 'Unknown';
const iconColor = 'var(--trashie-ui-faded-black-60)';

const ActivityListRow = ({ rowData }) => {
  const {
    id,
    createdAt,
    amount: rowAmount,
    typeId: transactionType,
    transactionStatus,
    productName,
    typeId,
  } = rowData;

  const {
    label: transactionActivityTypeLabel,
    icon: TransactionActivityTypeIcon,
  } = getTransactionActivityType({
    productName,
    id,
    typeId,
  }).getData(rowAmount);

  return (
    <div className={main} key={`activity-row-${id}`}>
      <div className={container}>
        <div className={`${rowContent} ${details}`}>
          <TransactionActivityTypeIcon color={iconColor} />
          <div className={detailsContainer}>
            <div className={detailsTitle}>
              <span className={date}>
                {`${formatMMDDYY(createdAt)} - ${transactionActivityTypeLabel}` }
              </span>
              {!isPaidStatusType(transactionStatus) && (
              <div className={`${rowContent} ${status}`}>
                <span className={statusText}>
                  { transactionStatus}
                </span>
              </div>
              )}
            </div>
            <div className={`${rowContent}`}>
              <span className={merchant}>
                {productName || DATA_NOT_AVAILABLE}
              </span>
            </div>
          </div>
        </div>
        <div className={`${rowContent} ${amount}`}>
          <span className={`${amountContainer} ${isNegativeTransaction(transactionType) && negative}`}>
            {formatCurrencyNumber(getTransactionAmount(rowAmount, transactionType), new Intl.NumberFormat('en-US', {
              style: 'decimal',
              minimumFractionDigits: 2,
            }))}
            <img src={TrashieCoin} className={tcIcon} alt="trashie cash icon" />
          </span>
        </div>
      </div>
    </div>
  );
};

export default ActivityListRow;
