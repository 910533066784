import { useCallback, useEffect, useState, useMemo } from 'react';

import InfoSection from '../../../../global/InfoSection';
import { Button, BUTTON_SIZE, BUTTON_TYPE } from '../../../../global/Button';
import ShippingProviderSelectItem from './ShippingProviderSelectItem';
import { fetchShippingProviders } from '../../../../utils/service';
import LoadingBar from '../../../../global/LoadingBar';
import {
  getNextButtonStyles,
  getOptionButtonStyles,
} from '../../../../utils/tbbRegistration';
import { POSTHOG_PROPERTIES } from '../../../../config/tracker';

import './SelectShippingProviderView.scss';

const DEFAULT_ITEMS_SHOWN = 2;

const SelectShippingProviderView = ({
  setShippingMethod,
  storeConfig,
  onSubmitShippingMethod,
  selectedShippingMethodIndex,
  setSelectedShippingMethodIndex,
  address,
  onError,
  recyclingProductType,
  stepTrackerProps,
}) => {
  const [shippingProviders, setShippingProviders] = useState([]);
  const [collapsed, setCollapsed] = useState(true);
  const [isLoading, setIsLoading] = useState(false);

  const getShippingProviders = useCallback(async () => {
    setIsLoading(true);

    const {
      data,
      error,
    } = await fetchShippingProviders({
      storeUid: storeConfig.uid,
      storeId: storeConfig.id,
      recyclingProductType,
    });

    if (error) {
      onError();
      setIsLoading(false);
      return;
    }

    const sortedShippingProviders = data.sort((a, b) => a.order - b.order);
    setShippingProviders(sortedShippingProviders);
    setShippingMethod(sortedShippingProviders[selectedShippingMethodIndex]);
    setIsLoading(false);
  });

  const onSelectShippingProvider = useCallback((index) => {
    setSelectedShippingMethodIndex(index);
    setShippingMethod(shippingProviders[index]);
  }, [shippingProviders]);

  useEffect(() => {
    getShippingProviders();
  }, []);

  const itemsToShow = useMemo(() => (
    storeConfig?.registerTbbFlow?.shippingProviderItemsShown || DEFAULT_ITEMS_SHOWN
  ));

  return (
    <div className="SelectShippingProviderView">
      <InfoSection titleText="DROP OFF LOCATION" />
      {isLoading ? (
        <LoadingBar />
      ) : (
        <>
          <div className="SelectShippingProviderView__items">
            {shippingProviders.slice(0, itemsToShow).map((shippingProvider, index) => (
              <ShippingProviderSelectItem
                key={shippingProvider.uid}
                index={index}
                shippingProvider={shippingProvider}
                selectedIndex={selectedShippingMethodIndex}
                address={address}
                onSelect={onSelectShippingProvider}
                storeConfig={storeConfig}
              />
            ))}
            {!collapsed && shippingProviders.slice(itemsToShow).map((shippingProvider, index) => (
              <ShippingProviderSelectItem
                key={shippingProvider.uid}
                index={index + itemsToShow}
                shippingProvider={shippingProvider}
                selectedIndex={selectedShippingMethodIndex}
                address={address}
                onSelect={onSelectShippingProvider}
                storeConfig={storeConfig}
              />
            ))}
          </div>
          {shippingProviders.length > itemsToShow && collapsed && (
            <Button
              type={BUTTON_TYPE.LINK_QUINARY}
              size={BUTTON_SIZE.LARGE}
              onClick={() => setCollapsed(false)}
              style={getOptionButtonStyles(storeConfig)}
            >
              SHOW OTHER OPTIONS
            </Button>
          )}
          <Button
            type={BUTTON_TYPE.QUATERNARY}
            size={BUTTON_SIZE.LARGE}
            onClick={() => onSubmitShippingMethod()}
            style={getNextButtonStyles(storeConfig)}
            disabled={!shippingProviders[selectedShippingMethodIndex]}
            trackerProps={{
              ...stepTrackerProps,
              [POSTHOG_PROPERTIES.TBB_SHIPPING_PROVIDER]:
                shippingProviders[selectedShippingMethodIndex]?.name,
            }}
          >
            {`CONTINUE${shippingProviders[selectedShippingMethodIndex]?.name ? ` WITH ${shippingProviders[selectedShippingMethodIndex].name}` : ''}`}
          </Button>
        </>
      )}
    </div>
  );
};

export default SelectShippingProviderView;
