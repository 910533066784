import { useEffect, useState, useCallback, useRef } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import classNames from 'classnames';
import { Box } from '@mui/material';

import { LIST_PAGE_SIZE, LIST_SORT_DIRECTION } from '../../config/config';
import {
  ANCHOR_GIFT_CARDS,
  ANCHOR_REWARDS,
  ANCHOR_WALLET,
  ROUTE_DEALS,
  ROUTE_GIFT_CARDS,
  ROUTE_REWARDS,
  ROUTE_WALLET,
  ROUTE_WALLET_GIFT_CARDS,
  ROUTE_WALLET_REWARDS,
  TAB_SEARCH_PARAM,
} from '../../config/routes';
import { REWARDS_ORDER_OPTIONS } from '../../config/rewards';
import WALLET_TABS from '../../config/wallet';
import { getSearchParam } from '../../utils/routes';
import { getUserGiftCards, getUserRewards } from '../../utils/service';
import DashboardNewSection from '../DashboardNew/DashboardNewSection';
import ListPaginator from '../../global/ListPaginator';
import LoadingBar from '../../global/LoadingBar';
import MyRewardsList from '../../global/MyRewardsList/MyRewardsList';
import MyGiftCardsList from '../../global/MyGiftCardsList';
import ZeroState from '../../global/MyRewardsList/ZeroState';
import Tabs from '../../global/Mui/Tabs';
import Tab from '../../global/Mui/Tab';
import { isProd } from '../../../../config/config';
import WandSparklesIcon from '../../images/wand-sparkles.svg';
import IconGem from '../../images/icon-gem.svg';
import WalletIcon from '../../images/wallet-icon.svg';

import './Wallet.scss';
import useHasAccessToGiftCards from '../../../../hooks/useHasAccessToGiftCards';

const DEFAULT_PAGE = 1;

const PREVIEW_PAGE_SIZE = 2;

const Wallet = () => {
  const timeoutRef = useRef(null);
  const history = useHistory();
  const { search } = useLocation();
  const { hasAccessToGiftCards } = useHasAccessToGiftCards();

  const query = new URLSearchParams(search);
  const tabSearchParam = getSearchParam(query, TAB_SEARCH_PARAM);

  const [tab, setTab] = useState(tabSearchParam || WALLET_TABS.DEALS);
  const [listData, setListData] = useState([]);
  const [giftCardsListData, setGiftCardsListData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingGiftCards, setIsLoadingGiftCards] = useState(true);
  const [page, setPage] = useState(DEFAULT_PAGE);
  const [totalItems, setTotalItems] = useState(0);

  const loadRewards = useCallback(async (type) => {
    setIsLoading(true);

    const {
      data: rewardsData,
      error: rewardsDataError,
    } = await getUserRewards({
      pageSize: type === WALLET_TABS.DEALS ? LIST_PAGE_SIZE : PREVIEW_PAGE_SIZE,
      page,
      orderBy: REWARDS_ORDER_OPTIONS.REDEEMED_AT,
      orderType: LIST_SORT_DIRECTION.DESC,
      type,
    });

    if (rewardsDataError) {
      setTotalItems(0);
      setListData([]);
      setPage(DEFAULT_PAGE);
      setIsLoading(false);
      return;
    }

    const {
      count,
      result: rewards,
    } = rewardsData;

    setTotalItems(count);
    setListData(rewards);
    setIsLoading(false);

    const mainElement = document.getElementById(ANCHOR_WALLET);

    if (mainElement) {
      timeoutRef.current = setTimeout(() => {
        mainElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
      }, 0);
    }
  }, [page]);

  const loadGiftCards = useCallback(async (type) => {
    if (type === WALLET_TABS.DEALS) {
      setGiftCardsListData([]);
      setIsLoadingGiftCards(true);
      return;
    }

    setIsLoadingGiftCards(true);

    const {
      data: giftCardsData,
      error: giftCardsDataError,
    } = await getUserGiftCards({
      pageSize: PREVIEW_PAGE_SIZE,
      page,
      orderBy: REWARDS_ORDER_OPTIONS.REDEEMED_AT,
      orderType: LIST_SORT_DIRECTION.DESC,
    });

    if (giftCardsDataError) {
      setGiftCardsListData([]);
      setIsLoadingGiftCards(false);
      return;
    }

    setGiftCardsListData(giftCardsData.giftCardOrders);
    setIsLoadingGiftCards(false);
  }, [page]);

  const updateSearchParam = useCallback((newTab = WALLET_TABS.DEALS) => {
    query.set(TAB_SEARCH_PARAM, newTab);

    history.push({
      pathname: ROUTE_WALLET,
      search: query.toString(),
    });
  }, [query, search]);

  const handleTabChange = useCallback((_, newTab) => {
    setTab(newTab);
    setTotalItems(0);
    setListData([]);
    setPage(DEFAULT_PAGE);
    setIsLoading(false);

    updateSearchParam(newTab);
  }, [updateSearchParam]);

  const handlePageSelection = useCallback((newPage) => {
    setPage(newPage);
  }, []);

  useEffect(() => {
    loadRewards(tab);
    loadGiftCards(tab);
  }, [page, tab]);

  useEffect(() => {
    if (!tabSearchParam) {
      updateSearchParam();
    }

    return () => timeoutRef?.current && clearTimeout(timeoutRef.current);
  }, []);

  return (
    <div id={ANCHOR_WALLET} className="Wallet">
      <div className="Wallet__content">
        <div className="Wallet__content--container">
          <Box
            className={classNames('Wallet__content--tabs', { hasEnvBanner: !isProd() })}
          >
            <Tabs
              value={tab}
              onChange={handleTabChange}
              variant="fullWidth"
              aria-label="rewards tabs"
            >
              {(Object.values(WALLET_TABS)).map(tabId => (
                <Tab
                  key={tabId}
                  value={tabId}
                  label={tabId}
                />
              ))}
            </Tabs>
          </Box>
          <div className="Wallet__content--section">
            {tab === WALLET_TABS.DEALS && (
              !isLoading && !listData.length ? (
                <ZeroState
                  icon={WandSparklesIcon}
                  title="Claim deals and earn TrashieCash™ back when using them"
                  buttonLabel="Browse all deals"
                  buttonRoute={ROUTE_DEALS}
                />
              ) : (
                <div className="Wallet__content--section-preview">
                  {(isLoading && (
                    <LoadingBar className="Wallet__content--loader" />
                  )) || (
                    <MyRewardsList rewardsList={listData} />
                  )}
                  <ListPaginator
                    pageSize={LIST_PAGE_SIZE}
                    totalItems={totalItems}
                    currentPage={page}
                    isLoading={isLoading}
                    onPageSelection={handlePageSelection}
                  />
                </div>
              )
            )}
            {tab === WALLET_TABS.REWARDS && (
              <>
                <DashboardNewSection
                  anchor={ANCHOR_REWARDS}
                  title="Premium Rewards"
                  onClick={listData.length ? () => history.push(ROUTE_WALLET_REWARDS) : undefined}
                  isLoading={isLoading}
                  noData={listData.length ? undefined : (
                    <ZeroState
                      icon={IconGem}
                      title="Use your trashiecash on premium rewards"
                      buttonLabel="Browse premium rewards"
                      buttonRoute={ROUTE_REWARDS}
                    />
                  )}
                  className="Wallet__content--section-preview"
                  contained
                >
                  <MyRewardsList rewardsList={listData} />
                </DashboardNewSection>
                {Boolean(hasAccessToGiftCards || giftCardsListData.length) && (
                  <DashboardNewSection
                    anchor={ANCHOR_GIFT_CARDS}
                    title="Gift Cards"
                    onClick={giftCardsListData.length ? (
                      () => history.push(ROUTE_WALLET_GIFT_CARDS)
                    ) : undefined}
                    isLoading={isLoadingGiftCards}
                    noData={giftCardsListData.length ? undefined : (
                      <ZeroState
                        icon={WalletIcon}
                        title="REDEEM TRASHIECASH ON AWESOME GIFT CARDS"
                        buttonLabel="Browse all gift cards"
                        buttonRoute={ROUTE_GIFT_CARDS}
                      />
                    )}
                    className="Wallet__content--section-preview"
                    contained
                  >
                    <MyGiftCardsList giftCardsList={giftCardsListData} />
                  </DashboardNewSection>
                )}
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Wallet;
