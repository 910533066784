import { useEffect, useState, useCallback, useRef } from 'react';

import { LIST_PAGE_SIZE, LIST_SORT_DIRECTION } from '../../config/config';
import { ANCHOR_GIFT_CARDS, ROUTE_GIFT_CARDS, ROUTE_WALLET, TAB_SEARCH_PARAM } from '../../config/routes';
import { REWARDS_ORDER_OPTIONS } from '../../config/rewards';
import WALLET_TABS from '../../config/wallet';
import ListPaginator from '../../global/ListPaginator';
import LoadingBar from '../../global/LoadingBar';
import MyGiftCardsList from '../../global/MyGiftCardsList';
import ZeroState from '../../global/MyRewardsList/ZeroState';
import { getUserGiftCards } from '../../utils/service';
import ButtonBack from '../../global/ButtonBack/ButtonBack';
import ListHeaders from '../../global/ListHeaders';
import { isMobile } from '../../../../hooks/useBreakPoint';
import WalletIcon from '../../images/wallet-icon.svg';

import './MyGiftCards.scss';

const DEFAULT_PAGE = 1;
const GO_TO_WALLET = 'GO TO WALLET';

const MyGiftCards = () => {
  const timeoutRef = useRef(null);
  const mobile = isMobile();

  const [listData, setListData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [page, setPage] = useState(DEFAULT_PAGE);
  const [totalItems, setTotalItems] = useState(0);

  const loadGiftCards = useCallback(async () => {
    setIsLoading(true);

    const {
      data: giftCardsData,
      error: giftCardsDataError,
    } = await getUserGiftCards({
      pageSize: LIST_PAGE_SIZE,
      page,
      orderBy: REWARDS_ORDER_OPTIONS.REDEEMED_AT,
      orderType: LIST_SORT_DIRECTION.DESC,
    });

    if (giftCardsDataError) {
      setTotalItems(0);
      setListData([]);
      setPage(DEFAULT_PAGE);
      setIsLoading(false);
      return;
    }

    const {
      total,
      giftCardOrders,
    } = giftCardsData;

    setTotalItems(total);
    setListData(giftCardOrders);
    setIsLoading(false);

    const mainElement = document.getElementById(ANCHOR_GIFT_CARDS);

    if (mainElement) {
      timeoutRef.current = setTimeout(() => {
        mainElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
      }, 0);
    }
  }, [page]);

  const handlePageSelection = useCallback((newPage) => {
    setPage(newPage);
  }, []);

  useEffect(() => {
    loadGiftCards();
  }, [page]);

  useEffect(() => (
    () => timeoutRef?.current && clearTimeout(timeoutRef.current)
  ), []);

  return (
    <div id={ANCHOR_GIFT_CARDS} className="MyGiftCards">
      <div className="MyGiftCards__content">
        <div className="MyGiftCards__content--container">
          {!mobile && (
            <>
              <ButtonBack
                route={`${ROUTE_WALLET}?${TAB_SEARCH_PARAM}=${WALLET_TABS.REWARDS}`}
              >
                {GO_TO_WALLET}
              </ButtonBack>
              <ListHeaders title="Gift Cards" />
            </>
          )}
          <div className="MyGiftCards__content--section">
            {!isLoading && !listData.length ? (
              <ZeroState
                icon={WalletIcon}
                title="REDEEM TRASHIECASH ON AWESOME GIFT CARDS"
                buttonLabel="Browse all gift cards"
                buttonRoute={ROUTE_GIFT_CARDS}
              />
            ) : (
              <>
                {(isLoading && (
                  <LoadingBar className="MyGiftCards__content--loader" />
                )) ||
                  (
                    <MyGiftCardsList giftCardsList={listData} />
                  )}
                <ListPaginator
                  pageSize={LIST_PAGE_SIZE}
                  totalItems={totalItems}
                  currentPage={page}
                  isLoading={isLoading}
                  onPageSelection={handlePageSelection}
                />
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default MyGiftCards;
