import { useEffect, useState, useCallback, useRef } from 'react';

import { LIST_PAGE_SIZE, LIST_SORT_DIRECTION } from '../../config/config';
import { ANCHOR_MY_REWARDS, ROUTE_REWARDS, ROUTE_WALLET, TAB_SEARCH_PARAM } from '../../config/routes';
import { REWARDS_ORDER_OPTIONS } from '../../config/rewards';
import WALLET_TABS from '../../config/wallet';
import ListPaginator from '../../global/ListPaginator';
import LoadingBar from '../../global/LoadingBar';
import MyRewardsList from '../../global/MyRewardsList/MyRewardsList';
import ZeroState from '../../global/MyRewardsList/ZeroState';
import { getUserRewards } from '../../utils/service';
import ButtonBack from '../../global/ButtonBack/ButtonBack';
import ListHeaders from '../../global/ListHeaders';
import { isMobile } from '../../../../hooks/useBreakPoint';
import IconGem from '../../images/icon-gem.svg';

import './MyRewards.scss';

const DEFAULT_PAGE = 1;
const GO_TO_WALLET = 'GO TO WALLET';

const MyRewards = () => {
  const timeoutRef = useRef(null);
  const mobile = isMobile();

  const [listData, setListData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [page, setPage] = useState(DEFAULT_PAGE);
  const [totalItems, setTotalItems] = useState(0);

  const loadRewards = useCallback(async () => {
    setIsLoading(true);

    const {
      data: rewardsData,
      error: rewardsDataError,
    } = await getUserRewards({
      pageSize: LIST_PAGE_SIZE,
      page,
      orderBy: REWARDS_ORDER_OPTIONS.REDEEMED_AT,
      orderType: LIST_SORT_DIRECTION.DESC,
      type: WALLET_TABS.REWARDS,
    });

    if (rewardsDataError) {
      setTotalItems(0);
      setListData([]);
      setPage(DEFAULT_PAGE);
      setIsLoading(false);
      return;
    }

    const {
      count,
      result: rewards,
    } = rewardsData;

    setTotalItems(count);
    setListData(rewards);
    setIsLoading(false);

    const mainElement = document.getElementById(ANCHOR_MY_REWARDS);

    if (mainElement) {
      timeoutRef.current = setTimeout(() => {
        mainElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
      }, 0);
    }
  }, [page]);

  const handlePageSelection = useCallback((newPage) => {
    setPage(newPage);
  }, []);

  useEffect(() => {
    loadRewards();
  }, [page]);

  useEffect(() => (
    () => timeoutRef?.current && clearTimeout(timeoutRef.current)
  ), []);

  return (
    <div id={ANCHOR_MY_REWARDS} className="MyRewards">
      <div className="MyRewards__content">
        <div className="MyRewards__content--container">
          {!mobile && (
            <>
              <ButtonBack
                route={`${ROUTE_WALLET}?${TAB_SEARCH_PARAM}=${WALLET_TABS.REWARDS}`}
              >
                {GO_TO_WALLET}
              </ButtonBack>
              <ListHeaders title="Premium Rewards" />
            </>
          )}
          <div className="MyRewards__content--section">
            {!isLoading && !listData.length ? (
              <ZeroState
                icon={IconGem}
                title="Use your trashiecash on premium rewards"
                buttonLabel="Browse premium rewards"
                buttonRoute={ROUTE_REWARDS}
              />
            ) : (
              <>
                {(isLoading && (
                  <LoadingBar className="MyRewards__content--loader" />
                )) ||
                  (
                    <MyRewardsList rewardsList={listData} />
                  )}
                <ListPaginator
                  pageSize={LIST_PAGE_SIZE}
                  totalItems={totalItems}
                  currentPage={page}
                  isLoading={isLoading}
                  onPageSelection={handlePageSelection}
                />
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default MyRewards;
