import { useState, useCallback } from 'react';
import _ from 'lodash';
import FormControlLabel from '../../../../global/Mui/FormControlLabel';
import Checkbox from '../../../../global/Mui/Checkbox';
import Dialog from '../../../../global/Mui/Dialog';
import { Button, BUTTON_SIZE, BUTTON_TYPE } from '../../../../global/Button';
import {
  RECYCLING_PRODUCT_ITEMS,
  RECYCLING_PRODUCT_ITEMS_NOT_ALLOWED,
} from '../../../../utils/tbbRegistration';

import './RecyclingList.scss';

const advice = 'Device not listed above?\nFind out which devices can’t be shipped';

const RecyclingList = ({
  stepTrackerProps,
  onSubmit,
}) => {
  const [showDialog, setShowDialog] = useState(false);
  const [selectedItems, setSelectedItems] = useState([]);

  const handleCheckboxChange = useCallback((event) => {
    const { name, checked } = event.target;
    if (checked) {
      setSelectedItems([...selectedItems, name]);
    } else {
      setSelectedItems(selectedItems.filter((item) => item !== name));
    }
  }, [selectedItems]);

  return (
    <div className="RecyclingList">
      <div className="RecyclingList__container">
        <div className="RecyclingList__container--title">
          What are you recycling?
        </div>
        <div className="RecyclingList__container--content">
          <div className="RecyclingList__container--content--itemsList">
            {
            RECYCLING_PRODUCT_ITEMS.map((item) => (
              <div className="RecyclingList__container--content--itemsList--item">
                <FormControlLabel
                  label={item.label}
                  control={(
                    <Checkbox
                      name={item.label}
                      checkedColor="#2649D4 !important"
                      onChange={handleCheckboxChange}
                    />
                )}
                />
              </div>
            ))
          }
          </div>

          <Button
            type={BUTTON_TYPE.QUATERNARY}
            size={BUTTON_SIZE.LARGE}
            disabled={_.isEmpty(selectedItems)}
            onClick={() => onSubmit()}
            trackerProps={stepTrackerProps}
          >
            continue
          </Button>

          <div className="RecyclingList__container--content--message">
            {advice}
            <Button
              type={BUTTON_TYPE.LINK_QUATERNARY}
              size={BUTTON_SIZE.SMALL}
              onClick={() => setShowDialog(true)}
            >
              view list
            </Button>
          </div>

        </div>
      </div>
      <Dialog
        open={showDialog}
        onClose={() => setShowDialog(false)}
      >
        <div className="RecyclingList__container--dialog">
          <div className="RecyclingList__container--dialog--title">
            ITEMS NOT ALLOWED
          </div>
          <div className="RecyclingList__container--dialog--content">
            {
            RECYCLING_PRODUCT_ITEMS_NOT_ALLOWED.map((item) => (
              <div className="RecyclingList__container--dialog--content--item">
                {item}
              </div>
            ))
          }
          </div>
          <Button
            type={BUTTON_TYPE.QUATERNARY}
            size={BUTTON_SIZE.LARGE}
            onClick={() => setShowDialog(false)}
          >
            close
          </Button>
        </div>
      </Dialog>
    </div>
  );
};

export default RecyclingList;
