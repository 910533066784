const RefundSvg = ({ color = '#6F706F' }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={16}
    height={16}
    fill="none"
  >
    <g
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.333}
    >
      <path d="M6 9.333 2.667 6 6 2.667" />
      <path d="M2.667 6h7a3.667 3.667 0 0 1 0 7.333H7.333" />
    </g>
  </svg>
);
export default RefundSvg;
