import { useState } from 'react';

import MyGiftCardItem from './MyGiftCardItem';

import './MyGiftCardsList.scss';

const MyGiftCardsList = ({ giftCardsList }) => {
  const [itemCopied, setItemCopied] = useState(null);

  return (
    <div
      role="list"
      className="MyGiftCardsList"
    >
      <div className="MyGiftCardsList__giftCardRow">
        {giftCardsList.map((giftCardItem) => (
          <MyGiftCardItem
            key={`MyRewardItem-${giftCardItem.uid}`}
            giftCardItem={giftCardItem}
            onCopy={(uid) => setItemCopied(uid)}
            isCopied={itemCopied === giftCardItem.uid}
          />
        ))}
      </div>
    </div>
  );
};

export default MyGiftCardsList;
