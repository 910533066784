import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import InfoSection from '../../../../global/InfoSection';
import Input from '../../../../global/InputNew';
import { Button, BUTTON_SIZE, BUTTON_TYPE } from '../../../../global/Button';
import Style from '../../Register.module.scss';
import { capitalizeWords, isNullOrEmpty } from '../../../../utils/utils';
import StateSelector from '../../Components/StateSelector';
import { isZipValid } from '../../../../utils/locations';

import './EnterNewAddressView.scss';

const {
  bottomSectionContainer,
  bottomSectionTitle,
} = Style;

const EnterNewAddressView = ({
  onBackToSelectAddress,
  userData,
  setFromAddress,
  storeConfig,
  stepTrackerProps,
}) => {
  const [newAddress, setNewAddress] = useState('');
  const [firstName, setFirstName] = useState(capitalizeWords(userData?.firstName || ''));
  const [lastName, setLastName] = useState(capitalizeWords(userData?.lastName || ''));
  const [apartment, setApartment] = useState('');
  const [city, setCity] = useState('');
  const [selectedState, setSelectedState] = useState('');
  const [selectedStateCode, setSelectedStateCode] = useState('');
  const [zip, setZip] = useState('');
  const [zipError, setZipError] = useState(false);
  const [shouldEnableSubmit, setShouldEnableSubmit] = useState(false);

  const autocompleteInput = useRef(null);
  const [sessionToken, setSessionToken] = useState(
    new window.google.maps.places.AutocompleteSessionToken(),
  );

  const handlePlaceChanged = (autocompleteObj) => {
    const place = autocompleteObj.getPlace();
    setCity(place.address_components.find((component) => component.types.includes('locality'))?.long_name);
    setSelectedState(place.address_components.find((component) => component.types.includes('administrative_area_level_1'))?.long_name);
    setSelectedStateCode(place.address_components.find((component) => component.types.includes('administrative_area_level_1'))?.short_name);
    setNewAddress(place.name);
    const postalCode = place.address_components.find((component) => component.types.includes('postal_code'))?.long_name;
    const zipSuffix = place.address_components.find((component) => component.types.includes('postal_code_suffix'))?.long_name;
    const zipWithSuffix = zipSuffix ? `${postalCode}-${zipSuffix}` : postalCode;
    setZip(zipWithSuffix);
    setSessionToken(new window.google.maps.places.AutocompleteSessionToken());
  };

  useEffect(() => {
    const autocompleteObj = new window.google.maps.places.Autocomplete(
      autocompleteInput.current,
      {
        types: ['address'],
        sessionToken,
        componentRestrictions: { country: ['us'] },
      },
    );
    autocompleteObj.setFields(['address_components', 'name']);
    autocompleteObj.addListener('place_changed', () => handlePlaceChanged(autocompleteObj));

    return () => {
      window.google.maps.event.clearInstanceListeners(autocompleteObj);
    };
  }, []);

  const inputFieldsParameters = useMemo(() => [
    {
      id: 'firstName',
      placeholder: 'First name*',
      value: firstName,
      onChange: setFirstName,
    },
    {
      id: 'lastName',
      placeholder: 'Last name*',
      value: lastName,
      onChange: setLastName,
    },
    {
      id: 'address',
      placeholder: 'Address*',
      value: newAddress || '',
      onChange: setNewAddress,
      inputRef: autocompleteInput,
    },
    {
      id: 'complement',
      placeholder: 'Apartment, suite, etc. (optional)',
      value: apartment || '',
      onChange: setApartment,
    },
    {
      id: 'city',
      placeholder: 'City*',
      value: city || '',
      onChange: setCity,
    },
  ], [firstName, lastName, newAddress, apartment, city]);

  const isFormValid = useCallback(() => (
    firstName.length &&
    lastName.length &&
    !isNullOrEmpty(newAddress) &&
    !isNullOrEmpty(city) &&
    !isNullOrEmpty(zip) &&
    selectedStateCode?.length &&
    selectedState.length
  ));

  useEffect(() => {
    if (isZipValid(zip) || !zip) {
      setZipError(false);
    } else {
      setZipError(true);
      setShouldEnableSubmit(false);
    }
  }, [zip]);

  useEffect(() => {
    setShouldEnableSubmit(isFormValid());
  }, [firstName, lastName, newAddress, city, zip, selectedStateCode, selectedState]);

  const onSubmit = useCallback(() => {
    if (isFormValid() && isZipValid(zip)) {
      const firstNameCaps = capitalizeWords(firstName);
      const lastNameCaps = capitalizeWords(lastName.trim());
      const cityCaps = capitalizeWords(city);
      const address1Caps = capitalizeWords(newAddress);
      const addressObject = {
        firstName: firstNameCaps,
        lastName: lastNameCaps,
        address1: address1Caps,
        address2: apartment,
        city: cityCaps,
        provinceCode: selectedStateCode,
        zip: zip.replace(/\s/g, ''),
      };
      setFromAddress(addressObject);
      setZipError(false);
      setShouldEnableSubmit(true);
    } else if (!isZipValid(zip)) {
      setZipError(true);
      setShouldEnableSubmit(false);
    }
  });

  useEffect(() => {
    if (storeConfig.registerTbbFlow?.isAuthFreeFlow) {
      setFirstName('');
      setLastName('');
    }
  }, [storeConfig]);

  return (
    <div className="EnterNewAddressView">
      <InfoSection
        titleText="Add a return address"
        descriptionText={'We\'ll use this address to create your pre-paid shipping label'}
      />
      {inputFieldsParameters.map((inputField) => (
        <div>
          <Input
            key={inputField.id}
            elementClassName="inputText"
            className="input"
            type="text"
            {...inputField}
            errorMessage=""
          />
        </div>
      ))}
      <StateSelector
        selectedState={selectedState}
        setSelectedState={setSelectedState}
        selectedStateCode={selectedStateCode}
        setSelectedStateCode={setSelectedStateCode}
      />
      <div className={`${zipError ? 'input-error' : ''}`}>
        <Input
          elementClassName="inputText"
          className={`input ${zipError ? 'input-error' : ''}`}
          type="text"
          id="zip"
          placeholder="Zip*"
          value={zip || ''}
          onChange={setZip}
          errorMessage=""
        />
        {zipError && (
        <div className="zip-error">
          <p>Invalid zip code</p>
        </div>
        )}
      </div>
      <div className="continue-button">
        <Button
          type={BUTTON_TYPE.QUATERNARY}
          size={BUTTON_SIZE.LARGE}
          onClick={onSubmit}
          disabled={!shouldEnableSubmit || zipError}
          style={!storeConfig.isTrashie ? ({
            background: storeConfig.registerTbbFlow?.nextButton?.bgColor,
            color: storeConfig.registerTbbFlow?.nextButton?.textColor,
            border: storeConfig?.registerTbbFlow?.nextButton?.bgColor,
          }) : {}}
          trackerProps={stepTrackerProps}
        >
          Continue
        </Button>
      </div>
      {!storeConfig.registerTbbFlow?.isAuthFreeFlow && (
        <div className={bottomSectionContainer}>
          <div className={bottomSectionTitle}>
            <p>Changed your mind?</p>
          </div>
          <Button
            type={BUTTON_TYPE.LINK_QUINARY}
            onClick={onBackToSelectAddress}
            style={!storeConfig.isTrashie ? ({
              color: storeConfig.registerTbbFlow?.nextButton?.bgColor,
            }) : {}}
          >
            Back to your addresses
          </Button>
        </div>
      )}
    </div>

  );
};

export default EnterNewAddressView;
