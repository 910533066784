import * as React from 'react';
import Box from '@mui/material/Box';
import Tabs from '@mui/material/Tabs';
import TabScrollButton from '@mui/material/TabScrollButton';
import { styled } from '@mui/material/styles';

const ScrollButtonFade = ({
  direction,
  disabled,
  withFilters = true,
  backgroundColor = 'var(--trashie-ui-faded-black-10)',
}) => (
  <Box
    className="scrollButtons-fade"
    sx={theme => ({
      width: '60px',
      height: '80px',
      backgroundImage: direction === 'right' ? (
        `linear-gradient(to right, rgb(255 255 255 / 0), ${backgroundColor} 40px)`
      ) : (
        `linear-gradient(to right, ${backgroundColor} 20px, rgb(255 255 255 / 0))`
      ),
      position: 'absolute',
      zIndex: -1,
      ...(direction === 'right' ? { right: withFilters ? '284px' : '40px' } : { left: '40px' }),
      ...(disabled ? { display: 'none' } : {}),
      [theme.breakpoints.down(1020)]: {
        ...(direction === 'right' ? { right: '40px' } : {}),
      },
      [theme.breakpoints.down('md')]: {
        display: 'none',
      },
    })}
  />
);

const ScrollButtonComponent = (props) => (
  <Box
    sx={{
      display: 'flex',
      opacity: 1,
      height: '80px',
      zIndex: 1,
    }}
  >
    <ScrollButtonFade {...props} />
    <TabScrollButton {...props} />
  </Box>
);

const StyledTabs = styled((props) => (
  <Tabs
    {...props}
    TabIndicatorProps={{ children: <span className="MuiTabs-indicatorSpan" /> }}
    ScrollButtonComponent={ScrollButtonComponent}
    TabScrollButtonProps={{
      withFilters: props.withFilters,
      backgroundColor: props.backgroundColor,
    }}
  />
))({
  backgroundColor: 'inherit',
  '& .MuiTabs-indicator': {
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: 'transparent',
  },
  '& .MuiTabs-indicatorSpan': {
    width: '100%',
    backgroundColor: '#292829',
  },
  '& .MuiTabs-scrollButtons': {
    border: '1px solid #696969',
    borderRadius: '50%',
    height: '24px',
    width: '24px',
    alignSelf: 'center',
    margin: '0 2px',
    '&:hover, &.Mui-focusVisible': {
      transform: 'scale(1.04)',
      boxShadow: '0 6px 16px rgba(0,0,0,0.12)',
    },
  },
});

export default StyledTabs;
