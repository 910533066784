import { useMemo } from 'react';

import { isLimitErrorCode, isUserLimitErrorCode } from '../../../utils/rewards';
import { formatExpirationDate, isPastDate } from '../../../utils/dates';

import TCCoin from '../../../images/TC-icon.svg';

import './Flag.scss';

/* Parent element MUST have 'position: relative' */

const Flag = ({
  status,
  isNew,
  isFreeReward,
  hasRewardCodes,
  exchangeValue,
  expireAt,
  allowTcCredits,
  tcCreditAmount,
  properties,
}) => {
  const {
    label,
    style,
    icon,
  } = useMemo(() => {
    switch (true) {
      case isLimitErrorCode(status):
        return {
          label: properties?.soldOut?.label || 'SOLD_OUT',
          style: properties?.soldOut?.style || {},
        };
      case (isUserLimitErrorCode(status) || hasRewardCodes):
        return {
          label: properties?.claimed?.label || 'CLAIMED',
          style: properties?.claimed?.style || {},
        };
      case (allowTcCredits && tcCreditAmount > 0):
        return {
          label: `${properties?.shopAndEarn?.label} ${tcCreditAmount}`,
          style: properties?.shopAndEarn?.style || {},
          icon: TCCoin,
        };
      case isNew:
        return {
          label: properties?.new?.label || 'NEW',
          style: properties?.new?.style || {},
        };
      case isFreeReward:
        return {
          label: properties?.free?.label || '',
          style: properties?.free?.style || {},
        };
      case (expireAt && !isPastDate(expireAt)):
        return {
          label: formatExpirationDate({ date: expireAt, label: 'Ending', limit: true }),
          style: properties?.flaggedValue?.style || {},
        };
      case (Number(exchangeValue) <= properties?.flaggedValue?.exchangeValueFlagged):
        return {
          label: `${properties?.flaggedValue.label}${exchangeValue}`,
          style: properties?.flaggedValue?.style || {},
        };
      default:
        return {};
    }
  }, [status, isNew, isFreeReward, hasRewardCodes, exchangeValue, expireAt, properties]);

  if (!label) {
    return null;
  }

  return (
    <div className="RewardFlag" style={{ ...style }}>
      <p>{label}</p>
      {icon && <img src={icon} alt="icon" className="RewardFlag__icon" />}
    </div>
  );
};

export default Flag;
