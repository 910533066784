import { useCallback, useMemo } from 'react';
import { usePostHog } from 'posthog-js/react';
import classNames from 'classnames';

import {
  getActionType,
  getEventName,
  getTrackerId,
  POSTHOG_CAPTURE_ATTRIBUTES,
  POSTHOG_PROPERTIES,
  TRACKER_IDS,
} from '../../../config/tracker';
import { useFirebaseAuth } from '../../../../global/FirebaseProvider/FirebaseProvider';
import TCCoinSvg from '../../../global/SvgComponents/TCCoin_svg';
import ChevronUpSvg from '../../../global/SvgComponents/ChevronUp_svg';
import IconLockCircle from '../../../images/icon-lock-circle.svg';
import { getGiftCardBrandValues } from '../../../utils/giftCards';

import './GiftCardItem.scss';

const {
  giftCards: {
    list: {
      giftCardItem: trackerGiftCardItem,
    },
  },
} = TRACKER_IDS;

const GiftCardItem = ({
  giftCardItem,
  onClick,
}) => {
  const { isSignedIn, user, userData } = useFirebaseAuth();
  const posthog = usePostHog();

  const {
    uid,
    name,
    giftCardUrl,
  } = useMemo(() => (
    giftCardItem
  ), [giftCardItem]);

  const {
    minDenomination,
    maxDenomination,
    minBalance,
    disabled,
  } = useMemo(() => (
    getGiftCardBrandValues({
      giftCardBrand: giftCardItem,
      currentBalance: userData?.balance?.currentBalance,
    })
  ), [giftCardItem]);

  const handleOnClick = useCallback(() => {
    posthog.capture(
      getEventName(trackerGiftCardItem),
      {
        [POSTHOG_PROPERTIES.GIFT_CARD_ID]: uid,
        [POSTHOG_PROPERTIES.GIFT_CARD_NAME]: name,
        ...(isSignedIn && user && ({
          $set: { email: user.email },
        })),
      },
    );

    onClick?.(giftCardItem);
  }, [onClick, isSignedIn, user, disabled, giftCardItem]);

  return (
    <div className={classNames('GiftCardItem', { disabled })}>
      {disabled && (
        <img
          className="GiftCardItem__gem"
          src={IconLockCircle}
          alt="gift card locked"
        />
      )}
      <button
        className={classNames('GiftCardItem__caret', { disabled })}
        id={`GiftCardItem__caret-${uid}`}
        onClick={handleOnClick}
        type="button"
        aria-label="goft card item caret"
        onKeyDown={(event) => {
          if (event.key === 'Enter' || event.key === ' ') {
            handleOnClick();
          }
        }}
        {...{
          [POSTHOG_CAPTURE_ATTRIBUTES.TRACKER_ID]: getTrackerId(trackerGiftCardItem),
          [POSTHOG_CAPTURE_ATTRIBUTES.ACTION_ID]: getActionType(trackerGiftCardItem),
          [POSTHOG_CAPTURE_ATTRIBUTES.REWARD_ID]: uid,
          [POSTHOG_CAPTURE_ATTRIBUTES.REWARD_NAME]: name,
        }}
      >
        <div className="GiftCardItem__caret--cover">
          <img
            className="GiftCardItem__caret--cover-logo"
            src={giftCardUrl}
            alt="brand logo"
          />
        </div>
        <div className="GiftCardItem__caret--content">
          <div className="GiftCardItem__caret--content-text">
            {`$${minDenomination} - $${maxDenomination}`}
          </div>
          <div className="GiftCardItem__caret--content-arrow">
            <ChevronUpSvg
              color="var(--trashie-ui-faded-black-80)"
              width={10}
            />
          </div>
        </div>
        <div className="GiftCardItem__caret--cta">
          <span
            className="GiftCardItem__caret--cta-label"
            title={disabled ? `Starting at ${minBalance} TC` : ''}
          >
            {disabled ? `Starting at ${minBalance}` : 'Get Gift Card'}
          </span>
          {disabled && (
            <TCCoinSvg width="12" height="12" />
          )}
        </div>
      </button>
    </div>
  );
};

export default GiftCardItem;
