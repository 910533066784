import { matchPath } from 'react-router-dom';

import SparklesSvg from '../global/SvgComponents/Sparkles_svg';
import RecycleSvg from '../global/SvgComponents/Recycle_svg';
import WalletSvg from '../global/SvgComponents/Wallet_svg';
import UserCircleSvg from '../global/SvgComponents/UserCircle_svg';

import {
  ROUTE_SIGNUP,
  ROUTE_LOGIN,
  ROUTE_HOME,
  ROUTE_LOGOUT,
  ROUTE_SETTINGS,
  ROUTE_ADDRESSES,
  ROUTE_REDEEM,
  ROUTE_DASHBOARD,
  ROUTE_PROFILE,
  ROUTE_REGISTER,
  ROUTE_WALLET,
  ROUTE_TAKE_BACK_BAGS,
  ROUTE_TAKE_BACK_BAGS_DETAILS,
  ROUTE_MY_ACCOUNT,
  TYPE_SEARCH_PARAM,
  EMAIL_SEARCH_PARAM,
  ROUTE_REWARD,
  PROMO_ID_PARAM,
  ROUTE_REWARDS,
  WF_RETURN_URL_SEARCH_PARAM,
  CATEGORY_SEARCH_PARAM,
  ROUTE_TRANSACTIONS,
  ROUTE_OFFERS,
  SORT_SEARCH_PARAM,
  FILTER_SEARCH_PARAMS,
  ROUTE_REWARDS_OLD,
  ROUTE_DEALS,
  REGISTER_SIGNED_AT_PARAM,
  REGISTER_SIGN_PARAM,
  REGISTER_CODE_PARAM,
  REGISTER_DATA_PARAM,
  ROUTES_TREE,
  ROUTE_GIFT_CARDS,
  ROUTE_WALLET_REWARDS,
  ROUTE_WALLET_GIFT_CARDS,
  TAB_SEARCH_PARAM,
} from '../config/routes';

const isLoginPath = (path) => path === ROUTE_LOGIN;

const isSignupPath = (path) => path === ROUTE_SIGNUP;

const isTrackedPath = (path) => !isLoginPath(path) && !isSignupPath(path);

const isRedeemPath = (path) => path === ROUTE_REDEEM || path === ROUTE_REGISTER;

const isContainerPath = (path) => (
  [
    ROUTE_HOME,
    ROUTE_REDEEM,
    ROUTE_REGISTER,
  ].includes(path)
);

const isTrashieContainerPublicPath = (path) => (
  [
    ROUTE_LOGIN,
    ROUTE_SIGNUP,
  ].includes(path)
);

const isTrashieContainerPath = (path) => (
  [
    ROUTE_LOGOUT,
    ROUTE_SETTINGS,
    ROUTE_ADDRESSES,
    ROUTE_DASHBOARD,
    ROUTE_PROFILE,
    ROUTE_WALLET,
    ROUTE_WALLET_REWARDS,
    ROUTE_WALLET_GIFT_CARDS,
    ROUTE_TAKE_BACK_BAGS,
    ROUTE_TAKE_BACK_BAGS_DETAILS,
    ROUTE_MY_ACCOUNT,
    ROUTE_REWARDS,
    ROUTE_REWARDS_OLD,
    ROUTE_TRANSACTIONS,
    ROUTE_OFFERS,
    ROUTE_DEALS,
    ROUTE_GIFT_CARDS,
  ].includes(path) ||
  path.match(ROUTE_REWARD) ||
  path.match(ROUTE_REWARDS) ||
  path.match(ROUTE_DEALS) ||
  path.match(ROUTE_GIFT_CARDS)
);

function getSearchParam(query, searchParam) {
  if (![
    TYPE_SEARCH_PARAM,
    EMAIL_SEARCH_PARAM,
    PROMO_ID_PARAM,
    WF_RETURN_URL_SEARCH_PARAM,
    CATEGORY_SEARCH_PARAM,
    SORT_SEARCH_PARAM,
    TAB_SEARCH_PARAM,
    FILTER_SEARCH_PARAMS.TYPE,
    FILTER_SEARCH_PARAMS.MIN_VALUE,
    FILTER_SEARCH_PARAMS.MAX_VALUE,
    FILTER_SEARCH_PARAMS.OTHER,
    REGISTER_CODE_PARAM,
    REGISTER_SIGN_PARAM,
    REGISTER_SIGNED_AT_PARAM,
    REGISTER_DATA_PARAM,
  ].includes(searchParam)) {
    return '';
  }

  return query.has(searchParam) ? decodeURIComponent(query.get(searchParam)) : '';
}

const isFooterPath = (path) => {
  const routes = [
    ROUTE_DASHBOARD,
    ROUTE_TAKE_BACK_BAGS,
    ROUTE_WALLET,
    ROUTE_MY_ACCOUNT,
  ];

  return routes.some(route => path === route);
};

const matchCurrentPath = route => matchPath(window.location.pathname, {
  path: route,
  exact: true,
});

const getRouteData = ({
  route = '',
  routeData = ROUTES_TREE,
  matchRoute = '',
  returnParent = false,
}) => {
  // eslint-disable-next-line no-restricted-syntax
  for (const [childrenRoute, childrenData] of Object.entries(routeData.children)) {
    if (
      (matchRoute && matchRoute === childrenRoute) ||
      (!matchRoute && matchCurrentPath(childrenRoute))
    ) {
      return returnParent ? ({
        route,
        data: routeData,
      }) : ({
        route: childrenRoute,
        data: childrenData,
      });
    }

    if (childrenData.children && Object.keys(childrenData.children).length) {
      const returnRoute = getRouteData({
        route: childrenRoute,
        routeData: childrenData,
        matchRoute,
        returnParent,
      });

      if (returnRoute.route) {
        return returnRoute;
      }
    }
  }

  return {};
};

const getCurrentRouteParentRoute = () => (
  getRouteData({ returnParent: true }).route ?? ''
);

const getCurrentRouteTitle = (includeIcon = false) => {
  const { title = '', icon } = getRouteData({}).data ?? {};

  if (includeIcon && icon) {
    return (
      <>
        <img src={icon} alt="route icon" />
        {title}
      </>
    );
  }

  return title;
};

const getRouteTitle = (matchRoute) => {
  const { title = '' } = getRouteData({ matchRoute }).data ?? {};

  return title;
};

const NAVIGATION_ITEMS = [
  {
    label: getRouteTitle(ROUTE_DASHBOARD),
    icon: SparklesSvg,
    route: ROUTE_DASHBOARD,
  },
  {
    label: getRouteTitle(ROUTE_TAKE_BACK_BAGS),
    icon: RecycleSvg,
    route: ROUTE_TAKE_BACK_BAGS,
  },
  {
    label: getRouteTitle(ROUTE_WALLET),
    icon: WalletSvg,
    route: ROUTE_WALLET,
  },
  {
    label: getRouteTitle(ROUTE_MY_ACCOUNT),
    icon: UserCircleSvg,
    route: ROUTE_MY_ACCOUNT,
  },
];

export {
  isLoginPath,
  isSignupPath,
  isTrackedPath,
  isRedeemPath,
  isContainerPath,
  isTrashieContainerPublicPath,
  isTrashieContainerPath,
  getSearchParam,
  isFooterPath,
  matchCurrentPath,
  getRouteData,
  getCurrentRouteTitle,
  getCurrentRouteParentRoute,
  NAVIGATION_ITEMS,
};
