const GiftSvg = ({ stroke = '#6F706F' }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={16}
    height={16}
    fill="none"
  >
    <g
      stroke={stroke}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.333}
    >
      <path d="M13.333 5.333H2.667A.667.667 0 0 0 2 6v1.333c0 .368.298.667.667.667h10.666A.667.667 0 0 0 14 7.333V6a.667.667 0 0 0-.667-.667ZM8 5.333V14" />
      <path d="M12.667 8v4.667A1.334 1.334 0 0 1 11.333 14H4.667a1.334 1.334 0 0 1-1.334-1.333V8M5 5.333A1.667 1.667 0 1 1 5 2c.643-.011 1.273.3 1.809.895.535.595.95 1.445 1.191 2.438.241-.993.656-1.843 1.192-2.438C9.727 2.301 10.357 1.99 11 2a1.667 1.667 0 1 1 0 3.333" />
    </g>
  </svg>
);
export default GiftSvg;
