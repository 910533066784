import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { REWARD_AMOUNT_TYPES } from '../../../config/rewards';
import { getUniqueLink } from '../../../utils/rewards';
import { Button, BUTTON_SIZE, BUTTON_TYPE } from '../../../global/Button';
import WalletSvg from '../../../global/SvgComponents/Wallet_svg';
import CheckCircleGreenIcon from '../../../images/check-circle-green-icon.svg';
import ArrowLeftRight from '../../../images/arrow-left-right.svg';

import './RewardContent.scss';

const STEPS = {
  COPY: 'copy',
  COPIED: 'copied',
};

const RewardContentSuccess = ({
  rewardItem,
  onFinish,
}) => {
  const timeoutRef = useRef(null);

  const [step, setStep] = useState(STEPS.COPY);
  const [count, setCount] = useState(5);

  const {
    code,
    pin,
    reward: {
      amountType,
      rewardAmount,
      type,
      store,
    },
  } = useMemo(() => rewardItem, [rewardItem]);

  const copyCode = useCallback(async () => {
    if (!code && !pin) {
      setStep(STEPS.COPIED);
      return;
    }

    navigator.clipboard.writeText(code || pin)
      .then(() => {
        setStep(STEPS.COPIED);
      })
      .catch(() => {
        setStep(STEPS.COPY);
      });
  }, [code, pin]);

  const startCountDown = useCallback(() => {
    const interval = setInterval(() => {
      setCount(prev => {
        if (prev === 0) {
          clearInterval(timeoutRef.current);
          window.location.href = rewardItem.redeemUrl || getUniqueLink(rewardItem.reward);
          onFinish?.();

          return prev;
        }

        return prev - 1;
      });
    }, 1000);

    timeoutRef.current = interval;
  }, [rewardItem]);

  useEffect(() => {
    if (step === STEPS.COPIED) {
      startCountDown();
    }
  }, [step]);

  useEffect(() => {
    copyCode();

    return () => clearInterval(timeoutRef.current);
  }, []);

  return (
    <div className="body-style success">
      <div className="body-top">
        <div className="success-icon">
          <WalletSvg
            className="trashie-coin-logo"
            color="var(--trashie-ui-faded-black-100)"
          />
        </div>
        <div className="bold-title">
          deal added to
          your wallet
        </div>
        <div className="success-desc">
          {REWARD_AMOUNT_TYPES[amountType]?.getText({ rewardAmount, type })}
          {` at ${store?.title}`}
        </div>
        {step === STEPS.COPY && (
          <Button
            type={BUTTON_TYPE.PRIMARY}
            size={BUTTON_SIZE.LARGE}
            onClick={copyCode}
          >
            Copy code and shop now
          </Button>
        )}
        {step === STEPS.COPIED && (
          <div className="success-actions">
            {code && (
              <div className="success-action">
                <img
                  className="success-action-img"
                  src={CheckCircleGreenIcon}
                  alt="store"
                />
                <span className="success-action-name">
                  Reward code added to clipboard
                </span>
              </div>
            )}
            <div className="success-action">
              <img
                className="success-action-img"
                src={ArrowLeftRight}
                alt="store"
              />
              <span className="success-action-name">
                {`Redirecting to ${store?.title} in ${count}s...`}
              </span>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default RewardContentSuccess;
