import { useHistory } from 'react-router-dom';
import { useCallback } from 'react';
import ArrowBack from '../../images/circle-arrow-left-gray.svg';
import './ButtonBack.scss';

const DEFAULT_LABEL = 'GO BACK';

const ButtonBack = ({
  children,
  onClick,
  route,
}) => {
  const history = useHistory();
  const defaultOnClick = useCallback(() => (route ? history.push(route) : history.goBack()));

  return (
    <button
      className="ButtonBack"
      type="button"
      onClick={onClick || defaultOnClick}
    >
      <img src={ArrowBack} alt="arrow-back" />
      <span className="BackButton__label">
        {children || DEFAULT_LABEL}
      </span>
    </button>
  );
};

export default ButtonBack;
