import TakeBagBackIcon from '../global/SvgComponents/Tbb_svg';
import Recycle from '../global/SvgComponents/Recycle_svg';
import WandSparklesIcon from '../global/SvgComponents/WandSparkles_svg';
import Sparkles from '../global/SvgComponents/Sparkles_svg';
import GiftSvg from '../global/SvgComponents/Gift_svg';
import RefundSvg from '../global/SvgComponents/Refund_svg';
import ShopCart from '../global/SvgComponents/ShopCart_svg';
import Refresh from '../global/SvgComponents/Refresh_svg';
import TCPlusSvg from '../global/SvgComponents/TCPlus_svg';

const TransactionActivityTypes = {
  TAKE_BACK_BAG: {
    id: 'Take Back Bag',
    getData: () => ({
      label: 'Earned',
      icon: TakeBagBackIcon,
    }),
  },
  TECH_TAKE_BACK_BOX: {
    id: 'Tech Take Back Box',
    getData: () => ({
      label: 'Earned',
      icon: Recycle,
    }),
  },
  REWARD: {
    id: 'REWARD',
    getData: (rowAmount) => (
      rowAmount === '0' ? ({
        label: 'Deal claimed',
        icon: WandSparklesIcon,
      }) : ({
        label: 'Spent',
        icon: Sparkles,
      })
    ),
  },
  GIFTCARD: {
    id: 'GIFT_CARD',
    getData: () => ({
      label: 'Spent',
      icon: Sparkles,
    }),
  },
  PROMO: {
    id: 'PROMO',
    getData: () => ({
      label: 'Reward',
      icon: GiftSvg,
    }),
  },
  GCREFUND: {
    id: 'GCREFUND',
    getData: () => ({
      label: 'Refunded',
      icon: RefundSvg,
    }),
  },
  WFC: {
    id: 'GCWITHDRAW',
    getData: () => ({
      label: 'Earned',
      icon: ShopCart,
    }),
  },
  MAN: {
    id: 'MAN',
    getData: () => ({
      label: 'Adjustment',
      icon: Refresh,
    }),
  },
  STRACKR: {
    id: 'STRACKR',
    getData: () => ({
      label: 'Earned',
      icon: TCPlusSvg,
    }),
  },
  DEFAULT: {
    id: 'DEFAULT',
    getData: () => ({
      label: 'Spent',
      icon: Sparkles,
    }),
  },
};

const TransactionStatusTypes = {
  TRANSACTION_PENDING: 'PENDING',
  TRANSACTION_PAID: 'PAID',
  TRANSACTION_DISQUALIFIED: 'DISQUALIFIED',
};

const TransactionType = {
  WITHDRAW: 1,
  REWARD: 2,
};

export {
  TransactionStatusTypes,
  TransactionActivityTypes,
  TransactionType,
};
