import { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import RewardsList from '../../Rewards/RewardsList';
import DashboardNewSection from '../../DashboardNew/DashboardNewSection';
import { getGenericError } from '../../../utils/errors';
import { getRewards, getConfigurations } from '../../../utils/service';
import { REWARD_TYPE } from '../../../config/rewards';
import {
  ANCHOR_DEALS,
  REWARD_ID_ROUTE_PARAM,
  ROUTE_DEALS,
  ROUTE_DEAL_STORE_REWARD_ID,
  STORE_ID_ROUTE_PARAM,
} from '../../../config/routes';
import { getTrackerId, POSTHOG_CAPTURE_ATTRIBUTES, TRACKER_IDS } from '../../../config/tracker';
import RedeemModal from '../../Reward/RedeemModal';

const PREVIEW_PAGE_SIZE = 4;

const {
  dashboard: {
    instantDeals: {
      viewAllButton: trackerViewAllButton,
    },
  },
} = TRACKER_IDS;

const DealsPreview = () => {
  const history = useHistory();

  const [rewards, setRewards] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState('');
  const [rewardProperties, setRewardProperties] = useState({});
  const [isLoadingConfig, setIsLoadingConfig] = useState(true);
  const [redeemRewardItem, setRedeemRewardItem] = useState(null);
  const [openRedeemModal, setOpenRedeemModal] = useState(false);

  const loadRewards = useCallback(async () => {
    setIsLoading(true);

    const {
      data,
      error: rewardsError,
    } = await getRewards({
      pageSize: PREVIEW_PAGE_SIZE,
      page: 1,
      ...{ exchangeValueMax: '0' },
    });

    if (rewardsError) {
      setRewards([]);
      setError(rewardsError.message || getGenericError());
      setIsLoading(false);
      return;
    }

    setRewards(data.rewards);
    setError('');
    setIsLoading(false);
  }, []);

  const rewardsConfig = useCallback(async () => {
    setIsLoadingConfig(true);
    const { value, error: configError } = await getConfigurations('rewardProperties');
    if (!configError) {
      setRewardProperties(value);
    }
    setIsLoadingConfig(false);
  }, []);

  const handleOnClick = useCallback((rewardItem, redeem = false) => {
    const { type } = rewardItem;
    if (openRedeemModal) {
      return;
    }

    if (redeem && type !== REWARD_TYPE.SEGMENTED_BY_PURCHASED_SKU) {
      setRedeemRewardItem(rewardItem);
      setOpenRedeemModal(true);
      return;
    }

    const relativePath = ROUTE_DEAL_STORE_REWARD_ID
      .replace(STORE_ID_ROUTE_PARAM, rewardItem.store?.id)
      .replace(REWARD_ID_ROUTE_PARAM, rewardItem.uid);

    history.push(relativePath);
  }, [history]);

  useEffect(() => {
    loadRewards();
    rewardsConfig();
  }, []);

  return (
    <DashboardNewSection
      anchor={ANCHOR_DEALS}
      title="Access Instant Deals"
      subTitle="NEW! Explore unlimited special offers and exclusive perks for all Trashie Members."
      onClick={() => history.push(ROUTE_DEALS)}
      isLoading={isLoading || isLoadingConfig}
      error={error}
      noData={rewards.length ? undefined : 'No Deals available at the moment'}
      ctaTrackerProps={{
        [POSTHOG_CAPTURE_ATTRIBUTES.TRACKER_ID]: getTrackerId(trackerViewAllButton),
      }}
    >
      <RewardsList
        rewardsList={rewards}
        onClick={handleOnClick}
        rewardProperties={rewardProperties}
      />
      <RedeemModal
        rewardItem={redeemRewardItem}
        showModal={openRedeemModal}
        onClose={() => setOpenRedeemModal(false)}
      />
    </DashboardNewSection>

  );
};

export default DealsPreview;
