import { useCallback, useEffect, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import {
  Image,
} from 'react-bootstrap';
import {
  TbbCardBalanceSection,
  TbbSpendSection,
} from './index';
import { getUser } from '../../../utils/service';
import { useFirebaseAuth } from '../../../../global/FirebaseProvider/FirebaseProvider';
import arrowIcon from '../../../images/right-arrow-icon.svg';
import RightArrow from '../../../images/chevron-down.svg';
import { DOWNLOAD_EXTENSION_URL, TRASHIE_WEBSITE_URL } from '../../../config/externalURL';
import Rewards from '../../Rewards';
import InformativeBanner from '../../InformativeBanner/InformativeBanner';
import { isMobile } from '../../../../../hooks/useBreakPoint';
import Offers from '../../Offers';
import { viewType } from '../../GlobalLogin';
import { getConfig } from '../../../../../config/config';
import { EMAIL_SEARCH_PARAM, ROUTE_LOGIN, TYPE_SEARCH_PARAM } from '../../../config/routes';

import Style from './TbbRewardPage.module.scss';

const {
  contentContainer,
  rewardPageWrapper,
  dashboardButtonArrow,
  descriptions,
  dashboardButton,
  title,
  informativeBannerLearnMoreText,
  informativeBannerButton,
  informativeBannerArrow,
} = Style;

const TbbRewardPage = ({
  creditAmt,
  storeConfig,
  couponData,
  handleCopy,
  emailInput,
  isNewUser,
  stepTrackerProps,
}) => {
  const { isSignedIn, user, userData } = useFirebaseAuth();
  const [userHasExtensionInstalled, setUserHasExtensionInstalled] = useState(true);
  const balanceUrl = useMemo(() => {
    if (isNewUser) {
      const queryString = new URLSearchParams({
        [TYPE_SEARCH_PARAM]: viewType.VIEW_EMAIL_FORM,
        [EMAIL_SEARCH_PARAM]: emailInput,
      });

      return `${getConfig('BASE_URL_TRASHIE')}${ROUTE_LOGIN}?${queryString}`;
    }

    return storeConfig.registerTbbFlow.rewardStep?.balanceUrl;
  }, [storeConfig, emailInput, isNewUser]);

  const loadUser = useCallback(async (userId) => {
    const loadedUser = await getUser(userId);
    setUserHasExtensionInstalled(loadedUser?.data?.isExtensionInstalled);
  });

  useEffect(() => {
    if (isSignedIn) {
      loadUser(userData?._id);
    }
  }, []);

  const isMobileSize = isMobile();

  return (
    <div>
      {
        ((isSignedIn && !userHasExtensionInstalled) || !isSignedIn)
          && storeConfig?.isTrashie ?
          (
            <InformativeBanner
              mainText={isMobileSize ? 'ADD THE CHROME EXTENSION TO EARN TRASHIECASH™.' :
                'ADD THE CHROME EXTENSION TO EARN TRASHIECASH™ AS YOU SHOP BRANDS YOU LOVE.'}
              ctaChildren={isMobileSize ?
                (
                  <Link target="_blank" to={{ pathname: TRASHIE_WEBSITE_URL }} className={informativeBannerLearnMoreText}>
                    <img src={RightArrow} className={informativeBannerArrow} alt="arrow" />
                  </Link>
                ) :
                (
                  <>
                    <Link target="_blank" to={{ pathname: TRASHIE_WEBSITE_URL }} className={informativeBannerLearnMoreText}>
                      LEARN MORE
                    </Link>
                    <Link target="_blank" to={{ pathname: DOWNLOAD_EXTENSION_URL }} className={informativeBannerButton}>
                      ADD TO CHROME
                    </Link>
                  </>
                )}
            />
          )
          : undefined
      }
      <div
        className={rewardPageWrapper}
      >
        <div className={contentContainer}>
          <TbbCardBalanceSection
            creditAmt={storeConfig.isTrashie ?
              creditAmt :
              storeConfig?.registerTbbFlow?.rewardStep?.cashValue}
            user={user}
            userData={userData}
            handleCopy={handleCopy}
            couponData={couponData}
            storeConfig={storeConfig}
            loggedIn={isSignedIn && user}
            balanceUrl={balanceUrl}
          />
        </div>
        {!storeConfig.isTrashie && <TbbSpendSection storeConfig={storeConfig} />}
        {storeConfig.isTrashie && (
          <Rewards
            isDashboard={false}
            storesId={[storeConfig.id]}
            userEmail={emailInput}
            isNewUser={isNewUser}
          />
        )}
        {
          ((storeConfig.isTrashie &&
            <Offers isDashBoard={false} />) || null)
        }
        {storeConfig.isTrashie && (
          <div style={{
            display: 'flex',
            flexFlow: 'column',
            paddingBottom: '56px',
          }}
          >
            <p
              className={title}
              style={{
                marginTop: '40px',
              }}
            >
              CHECK YOUR BALANCE

            </p>
            <p className={descriptions}>
              View your earnings and access more
              {' '}
              <br />
              deals below
            </p>
            <Link
              to={{ pathname: balanceUrl }}
              target="_blank"
              className={dashboardButton}
              style={{
                background: storeConfig.registerTbbFlow?.nextButton?.bgColor,
                color: storeConfig.registerTbbFlow?.nextButton?.textColor,
              }}
              trackerProps={stepTrackerProps}
            >
              TAKE ME TO MY DASHBOARD
              <Image
                style={{
                  marginLeft: '8px',
                }}
                className={dashboardButtonArrow}
                src={arrowIcon}
                alt="product-image"
              />
            </Link>
          </div>
        )}
      </div>
    </div>

  );
};

export default TbbRewardPage;
