import classNames from 'classnames';

import GIFT_CARD_STATUS from '../../../config/giftCards';
import HourGlassIcon from '../../../images/icon-hourglass.svg';
import ForbiddenCircleIcon from '../../../images/forbidden-circle-icon.svg';

const GiftCardStatusPill = ({ status }) => (
  <div className={classNames('GiftCardStatusPill', status)}>
    <img
      src={status === GIFT_CARD_STATUS.ERROR ? ForbiddenCircleIcon : HourGlassIcon}
      alt={status}
    />
    <span>{status === GIFT_CARD_STATUS.ERROR ? 'Request failed' : 'Processing'}</span>
  </div>
);

export default GiftCardStatusPill;
