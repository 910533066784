import { ROUTE_DASHBOARD_REWARDS } from '../../../config/routes';
import giftIcon from '../../../images/gift-icon.svg';

import './ZeroState.scss';

const ZeroState = ({
  icon,
  title,
  message,
  buttonLabel,
  buttonRoute,
}) => (
  <div className="ZeroState">
    <img className="ZeroState__image" src={icon || giftIcon} alt="icon" />
    <span className="ZeroState__title">
      {title || 'ACCESS YOUR REWARDS AS YOU SHOP'}
    </span>
    {message && (
      <span className="ZeroState__message">
        {message}
      </span>
    )}
    <a
      className="ZeroState__cta"
      href={buttonRoute || ROUTE_DASHBOARD_REWARDS}
      target="_blank"
      rel="noreferrer"
    >
      {buttonLabel || 'Get My Rewards Now'}
    </a>
  </div>
);

export default ZeroState;
