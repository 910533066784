import { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import GiftCardsList from '../GiftCardsList';
import DashboardNewSection from '../../DashboardNew/DashboardNewSection';
import { getGenericError } from '../../../utils/errors';
import { getGiftCardsBrands } from '../../../utils/service';
import {
  ANCHOR_GIFT_CARDS,
  BRAND_ID_ROUTE_PARAM,
  ROUTE_GIFT_CARDS,
  ROUTE_GIFT_CARDS_BRAND_ID,
} from '../../../config/routes';
import { getTrackerId, POSTHOG_CAPTURE_ATTRIBUTES, TRACKER_IDS } from '../../../config/tracker';

const PREVIEW_PAGE_SIZE = 8;

const {
  dashboard: {
    earnTrashiecash: {
      viewAllButton: trackerViewAllButton,
    },
  },
} = TRACKER_IDS;

const GiftCardsPreview = () => {
  const history = useHistory();

  const [listData, setListData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState('');

  const loadGiftCards = useCallback(async () => {
    setIsLoading(true);

    const {
      data,
      error: giftCardsError,
    } = await getGiftCardsBrands({
      pageSize: PREVIEW_PAGE_SIZE,
      page: 1,
    });

    if (giftCardsError) {
      setListData([]);
      setError(giftCardsError);
      setError(giftCardsError.message || getGenericError());
      setIsLoading(false);
      return;
    }

    setError('');
    setListData(data.brands);
    setIsLoading(false);
  }, []);

  const handleOnClick = useCallback(({ uid }) => {
    const relativePath = ROUTE_GIFT_CARDS_BRAND_ID.replace(BRAND_ID_ROUTE_PARAM, uid);

    history.push(relativePath);
  }, [history]);

  useEffect(() => {
    loadGiftCards();
  }, []);

  return (
    <DashboardNewSection
      anchor={ANCHOR_GIFT_CARDS}
      title="Gift Cards"
      subTitle="Spend your TrashieCash™ on exclusive rewards!"
      onClick={() => history.push(ROUTE_GIFT_CARDS)}
      isLoading={isLoading}
      error={error}
      noData={listData.length ? undefined : 'No gift cards available at the moment'}
      ctaTrackerProps={{
        [POSTHOG_CAPTURE_ATTRIBUTES.TRACKER_ID]: getTrackerId(trackerViewAllButton),
      }}
    >
      <GiftCardsList
        giftCardsList={listData}
        onClick={handleOnClick}
      />
    </DashboardNewSection>

  );
};

export default GiftCardsPreview;
