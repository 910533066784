import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import Markdown from 'react-markdown';
import { Cover, COVER_TYPE } from '../../../global/Rewards';
import { ROUTE_WALLET, WF_RETURN_URL_SEARCH_PARAM } from '../../../config/routes';
import {
  getConditionalModifier,
  getTrackerId,
  getActionType,
  TRACKER_IDS,
  POSTHOG_CAPTURE_ATTRIBUTES,
  MODIFIERS } from '../../../config/tracker';
import RewardSummary from './RewardSummary';
import CopyButton from '../../../global/CopyButton';
import { getUniqueLink, isDonationType, replaceRewardTags } from '../../../utils/rewards';
import { formatFullDateUTC, formatTransactionDate } from '../../../utils/dates';
import { REWARD_AMOUNT_TYPES, REWARD_EXPIRE_AT_TAG } from '../../../config/rewards';
import CollapsableSection, { ICON_SIZE } from '../../../global/CollapsableSection';
import TermsDisclaimer from '../../../global/TermsDisclaimer';
import { BUTTON_SIZE, BUTTON_TYPE, Button } from '../../../global/Button';

import Advice from '../Advice';

import './RewardContent.scss';
import { getSearchParam } from '../../../utils/routes';

const {
  myRewards: {
    detail: {
      uniqueLink: trackerUniqueLink,
      codeLink: trackerCodeLink,
      pinLink: trackerPinLink,
      copyCodeLink: trackerCopyCodeLink,
      copyPinLink: trackerCopyPinLink,
      shopButton: trackerShopButton,
    },
  },
} = TRACKER_IDS;

const COPY_DATA_TYPES = {
  CODE: 'code',
  PIN: 'pin',
};

const RewardSection = ({
  rewardItem,
  isFinalStep = false,
  onShowTerms,
  isDeal,
}) => {
  const { search } = useLocation();
  const query = new URLSearchParams(search);
  const timeoutRef = useRef(null);
  const [copiedData, setCopiedData] = useState(null);
  const history = useHistory();
  const wfReturnUrl = getSearchParam(query, WF_RETURN_URL_SEARCH_PARAM);

  const {
    code,
    expireAt,
    pin,
    redeemUrl,
    exchangeValue,
    amountType,
    rewardAmount,
    type,
    terms,
    forFirstTimeCustomerOnly,
    description,
    isValidOnDiscountedItems,
    isStackable,
    instructions,
    store,
    allowTcCredits,
  } = rewardItem;

  const isDonation = isDonationType(type);

  const typeText = useMemo(() => (
    isDonation ? 'DONATION' : (isDeal && 'DEAL') || 'REWARD'
  ), [isDeal, type]);

  const rewardAmountTypeText = useMemo(() => (
    REWARD_AMOUNT_TYPES[amountType]?.getText({ rewardAmount, type, format: true }) ?? ''
  ), [amountType, rewardAmount, type]);

  const uniqueLink = useMemo(() => (
    wfReturnUrl || getUniqueLink(rewardItem)
  ), [rewardItem]);

  const handleCopy = useCallback((dataType) => {
    const data = dataType === COPY_DATA_TYPES.PIN ? pin : code;

    setCopiedData(dataType);

    navigator.clipboard.writeText(data);

    timeoutRef.current = setTimeout(() => {
      window.location.href = uniqueLink;
    }, 500);
  }, [code, pin, uniqueLink]);

  const textMessage = useCallback(() => {
    const prefix = (
      <>
        {'You must use this '}
        <a
          href={uniqueLink}
          {...{
            [POSTHOG_CAPTURE_ATTRIBUTES.TRACKER_ID]: getTrackerId(trackerUniqueLink),
            [POSTHOG_CAPTURE_ATTRIBUTES.ACTION_ID]: getActionType(trackerUniqueLink),
            ...getConditionalModifier(
              POSTHOG_CAPTURE_ATTRIBUTES.MODIFIER_ID,
              MODIFIERS.WF_LINK,
              wfReturnUrl,
            ),
          }}
        >
          unique link
        </a>
      </>
    );

    switch (true) {
      case Boolean(code && pin):
        return (
          <>
            {prefix}
            {'. At checkout use your code: '}
            <a
              href={uniqueLink}
              {...{
                [POSTHOG_CAPTURE_ATTRIBUTES.TRACKER_ID]: getTrackerId(trackerCodeLink),
                [POSTHOG_CAPTURE_ATTRIBUTES.ACTION_ID]: getActionType(trackerCodeLink),
                ...getConditionalModifier(
                  POSTHOG_CAPTURE_ATTRIBUTES.MODIFIER_ID,
                  MODIFIERS.WF_LINK,
                  wfReturnUrl,
                ),
              }}
            >
              {code}
            </a>
            {' and your pin: '}
            <a
              href={uniqueLink}
              {...{
                [POSTHOG_CAPTURE_ATTRIBUTES.TRACKER_ID]: getTrackerId(trackerPinLink),
                [POSTHOG_CAPTURE_ATTRIBUTES.ACTION_ID]: getActionType(trackerPinLink),
                ...getConditionalModifier(
                  POSTHOG_CAPTURE_ATTRIBUTES.MODIFIER_ID,
                  MODIFIERS.WF_LINK,
                  wfReturnUrl,
                ),
              }}
            >
              {pin}
            </a>
          </>
        );
      case Boolean(code):
        return (
          <>
            {prefix}
            {'. At checkout use your code: '}
            <a
              href={uniqueLink}
              {...{
                [POSTHOG_CAPTURE_ATTRIBUTES.TRACKER_ID]: getTrackerId(trackerCodeLink),
                [POSTHOG_CAPTURE_ATTRIBUTES.ACTION_ID]: getActionType(trackerCodeLink),
                ...getConditionalModifier(
                  POSTHOG_CAPTURE_ATTRIBUTES.MODIFIER_ID,
                  MODIFIERS.WF_LINK,
                  wfReturnUrl,
                ),
              }}
            >
              {code}
            </a>
          </>
        );
      default:
        return (
          <>
            {prefix}
            {` to claim your ${typeText.toLowerCase()}.`}
          </>
        );
    }
  }, [code, pin, rewardItem]);

  useEffect(() => (
    () => timeoutRef?.current && clearTimeout(timeoutRef.current)
  ), []);

  return (
    <div className="body-style final">
      <div className="top">
        <Cover
          rewardItem={rewardItem}
          type={COVER_TYPE.FINAL}
        />
      </div>
      <div className="bottom">
        <div className="bottom--content">
          <div>
            <p className="subtitle">
              {`YOU ${isDonation ? 'REDEEMED' : 'SCORED'} `}
              {rewardAmountTypeText}
              !
            </p>
            <p className="description">
              {!isDonation && textMessage()}
            </p>
            {!isDonation && (
              <>
                <div className="cta-container">
                  <a
                    className="cta-shop"
                    href={uniqueLink}
                    {...{
                      [POSTHOG_CAPTURE_ATTRIBUTES.TRACKER_ID]: getTrackerId(trackerShopButton),
                      [POSTHOG_CAPTURE_ATTRIBUTES.ACTION_ID]: getActionType(trackerShopButton),
                      ...getConditionalModifier(
                        POSTHOG_CAPTURE_ATTRIBUTES.MODIFIER_ID,
                        MODIFIERS.WF_LINK,
                        wfReturnUrl,
                      ),
                    }}
                  >
                    SHOP NOW
                  </a>
                </div>
                {wfReturnUrl && (
                  <div>
                    <p className="subtitle">
                      EARN MORE TRASHIECASH™
                    </p>
                    <p className="description">
                      {`Earn more TrashieCash™ by activating the Trashie Chrome Extension offer when you shop at ${rewardItem.store.title}.`}
                    </p>
                  </div>
                )}
              </>
            )}
            <p className="title">{`${typeText} DETAILS`}</p>
            <div className="details">
              {!isDonation && (
                <div className="details__item">
                  <p className="details__item--title">
                    {code ? 'CODE' : `MY ${typeText}`}
                  </p>
                  <div className="details__item--data-container">
                    <CopyButton
                      className="details__item--data"
                      data={code || (
                        <a className="details__item--data details__item--link" href={redeemUrl}>
                          {`CLICK TO CLAIM MY ${typeText}`}
                        </a>
                      )}
                      onClick={code ? () => handleCopy(COPY_DATA_TYPES.CODE) : undefined}
                      copied={copiedData === COPY_DATA_TYPES.CODE}
                      trackerProps={{
                        [POSTHOG_CAPTURE_ATTRIBUTES.TRACKER_ID]: getTrackerId(trackerCopyCodeLink),
                        [POSTHOG_CAPTURE_ATTRIBUTES.ACTION_ID]: getActionType(trackerCopyCodeLink),
                        ...getConditionalModifier(
                          POSTHOG_CAPTURE_ATTRIBUTES.MODIFIER_ID,
                          MODIFIERS.WF_LINK,
                          wfReturnUrl,
                        ),
                      }}
                    />
                  </div>
                </div>
              )}
              {pin && (
                <div className="details__item">
                  <p className="details__item--title">PIN</p>
                  <div className="details__item--data-container">
                    <CopyButton
                      className="details__item--data"
                      data={(
                        <a className="details__item--data details__item--link" href={uniqueLink}>{pin}</a>
                      )}
                      onClick={() => handleCopy(COPY_DATA_TYPES.PIN)}
                      copied={copiedData === COPY_DATA_TYPES.PIN}
                      trackerProps={{
                        [POSTHOG_CAPTURE_ATTRIBUTES.TRACKER_ID]: getTrackerId(trackerCopyPinLink),
                        [POSTHOG_CAPTURE_ATTRIBUTES.ACTION_ID]: getActionType(trackerCopyPinLink),
                        ...getConditionalModifier(
                          POSTHOG_CAPTURE_ATTRIBUTES.MODIFIER_ID,
                          MODIFIERS.WF_LINK,
                          wfReturnUrl,
                        ),
                      }}
                    />
                  </div>
                </div>
              )}
              <div className="details__item">
                <p className="details__item--title">{typeText}</p>
                <p className="details__item--data">
                  {rewardAmountTypeText}
                </p>
              </div>
              {!isDonation && expireAt && (
                <div className="details__item">
                  <p className="details__item--title">EXPIRES ON</p>
                  <p className="details__item--data">
                    {formatTransactionDate(expireAt)}
                  </p>
                </div>
              )}
            </div>
          </div>
          {isFinalStep && (
            <div className="redirectSection">
              <div className="redirectSection__title">
                {`WHERE DO I FIND THIS ${typeText} FOR LATER USE?`}
              </div>
              <div className="redirectSection__text">
                {`You can access this and all of your ${typeText.toLowerCase()}s and their details in the My Wallet section.`}
              </div>
              <div className="redirectSection__cta">
                <Button
                  type={BUTTON_TYPE.SECONDARY}
                  size={BUTTON_SIZE.LARGE}
                  className="redirectSection__cta--button"
                  onClick={() => history.push(ROUTE_WALLET)}
                >
                  GO TO MY WALLET
                </Button>
              </div>
            </div>
          )}
          <div className="collapsable-sections-container">
            {instructions && (
              <CollapsableSection
                title={<p className="title">HOW TO USE</p>}
                content={(
                  <Markdown className="markdown collapsable-sections-md-content">
                    {instructions}
                  </Markdown>
                )}
                collapsed={false}
                iconSize={ICON_SIZE.LARGE}
              />
            )}
            <RewardSummary
              rewardItem={{
                exchangeValue,
                expireAt,
                forFirstTimeCustomerOnly,
                description,
                isValidOnDiscountedItems,
                isStackable,
                store,
                allowTcCredits,
              }}
            />
            <Advice />
          </div>
        </div>
        <div className="bottom--disclaimer">
          <TermsDisclaimer
            terms={replaceRewardTags(
              terms,
              {
                [REWARD_EXPIRE_AT_TAG]:
                  formatFullDateUTC(expireAt),
              },
            )}
            ctaText="VIEW ALL TERMS AND INSTRUCTIONS"
            termsTitle="TERMS AND INSTRUCTIONS"
            onHandleClick={onShowTerms}
          />
        </div>
      </div>
    </div>
  );
};

export default RewardSection;
