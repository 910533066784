import classNames from 'classnames';

import './GiftCardContent.scss';

export const CARD_TYPES = {
  DEFAULT: 'default',
  SMALL: 'small',
};

const GiftCardCover = ({
  logoUrl,
  uniqueUrl,
  value,
  type = CARD_TYPES.DEFAULT,
  trackerProps = {},
}) => (
  uniqueUrl ? (
    <a
      href={uniqueUrl}
      aria-label="merchant url"
      {...trackerProps}
    >
      <Cover
        type={type}
        logoUrl={logoUrl}
        value={value}
      />
    </a>
  ) : (
    <Cover
      type={type}
      logoUrl={logoUrl}
      value={value}
    />
  )
);

const Cover = ({
  type,
  logoUrl,
  value,
}) => (
  <div className={classNames('GiftCardCover', type)}>
    {value && (
      <div className="GiftCardCover__value">
        {value}
      </div>
    )}
    <img
      className={classNames('GiftCardCover__logo', type)}
      src={logoUrl}
      alt="brand logo"
    />
  </div>
);

export default GiftCardCover;
