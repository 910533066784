import Markdown from 'react-markdown';

import { useLocation } from 'react-router-dom';
import { BUTTON_SIZE, BUTTON_TYPE, Button } from '../../../global/Button';
import { getUniqueLink, replaceRewardTags } from '../../../utils/rewards';
import { formatFullDateUTC } from '../../../utils/dates';
import { REWARD_EXPIRE_AT_TAG } from '../../../config/rewards';

import './RewardContent.scss';
import {
  getActionType,
  getTrackerId,
  getConditionalModifier,
  MODIFIERS,
  POSTHOG_CAPTURE_ATTRIBUTES,
  TRACKER_IDS } from '../../../config/tracker';
import { getSearchParam } from '../../../utils/routes';
import { WF_RETURN_URL_SEARCH_PARAM } from '../../../config/routes';

const {
  myRewards: {
    terms: {
      imageLink: trackerImageLink,
    },
  },
} = TRACKER_IDS;

export const CONTENT_TERMS_TYPE = {
  DEFAULT: 'default',
  FINAL: 'final',
};

const RewardContentTerms = ({
  rewardItem,
  onClose,
  type = CONTENT_TERMS_TYPE.REDEEM,
}) => {
  const {
    terms = '',
    expireAt,
    store: {
      imgUrl,
      title,
      logoUrl,
      RewardLogoProperties,
    },
  } = rewardItem;
  const { search } = useLocation();
  const query = new URLSearchParams(search);
  const wfReturnUrl = getSearchParam(query, WF_RETURN_URL_SEARCH_PARAM);

  return (
    <div className="body-style terms">
      <div className="content">
        {type === CONTENT_TERMS_TYPE.FINAL ? (
          <a
            href={wfReturnUrl || getUniqueLink(rewardItem)}
            style={{ width: '100%' }}
            aria-label="card store url"
            {...{
              [POSTHOG_CAPTURE_ATTRIBUTES.TRACKER_ID]: getTrackerId(trackerImageLink),
              [POSTHOG_CAPTURE_ATTRIBUTES.ACTION_ID]: getActionType(trackerImageLink),
              ...getConditionalModifier(
                POSTHOG_CAPTURE_ATTRIBUTES.MODIFIER_ID,
                MODIFIERS.WF_LINK,
                wfReturnUrl,
              ),
            }}
          >
            <ImgContainer
              logoUrl={logoUrl}
              imgUrl={imgUrl}
              RewardLogoProperties={RewardLogoProperties}
            />
          </a>
        ) : (
          <ImgContainer
            logoUrl={logoUrl}
            imgUrl={imgUrl}
            RewardLogoProperties={RewardLogoProperties}
          />
        )}
        <div className="content__title">{`${title} TERMS AND INSTRUCTIONS`}</div>
        <Markdown className="markdown content__terms">
          {replaceRewardTags(terms, { [REWARD_EXPIRE_AT_TAG]: formatFullDateUTC(expireAt) })}
        </Markdown>
      </div>
      <div className="bottom">
        <Button
          className="close-button"
          type={BUTTON_TYPE.SECONDARY}
          size={BUTTON_SIZE.LARGE}
          onClick={onClose}
        >
          close
        </Button>
      </div>
    </div>
  );
};

const ImgContainer = ({
  logoUrl,
  imgUrl,
  RewardLogoProperties,
}) => (
  <div
    className="content__logo"
    alt="merchant-logo"
    style={{
      backgroundImage: `linear-gradient(0deg, rgba(0, 0, 0, 0.20) 0%, rgba(0, 0, 0, 0.20) 100%),url(${imgUrl})`,
    }}
  >
    <img
      className="content__logo--merchant-logo"
      src={logoUrl}
      style={{ ...RewardLogoProperties }}
      alt="merchant logo"
    />
  </div>
);

export default RewardContentTerms;
