export default function TCPlus({
  color = '#3333FF',
  width = '20',
  height = '20',
}) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_7897_16687)">
        <path
          d="M9.45448 14C9.27266 14.0909 9.09084 14.0909 8.90902 14C8.72721 14 8.6363 13.8182 8.54539 13.6364L6.54539 10.0909L5.45448 10.7273C5.27266 10.8182 5.09084 10.8182 4.99993 10.7273C4.81812 10.7273 4.72721 10.5455 4.6363 10.4545C4.54539 10.2727 4.54539 10.0909 4.6363 10C4.6363 9.81818 4.81812 9.72727 4.90902 9.63636L8.27266 7.72727C8.45448 7.63636 8.6363 7.63636 8.72721 7.72727C8.90902 7.72727 8.99993 7.90909 9.09084 8C9.18175 8.18182 9.18175 8.36363 9.09084 8.45454C9.09084 8.63636 8.90902 8.72727 8.81811 8.81818L7.72721 9.45454L9.72721 13C9.81811 13.1818 9.81811 13.3636 9.72721 13.5455C9.72721 13.7273 9.54539 13.8182 9.45448 13.9091V14Z"
          fill={color}
        />
        <path
          d="M13.9998 11.5454C13.4543 11.8182 12.9998 12 12.4543 12C11.9089 12 11.4543 11.8182 10.9998 11.5454C10.5453 11.2727 10.1816 10.9091 9.90889 10.3636C9.63616 9.81817 9.45435 9.27272 9.45435 8.81817C9.45435 8.27272 9.54526 7.81817 9.81798 7.36363C10.0907 6.90908 10.4543 6.54545 10.9998 6.27272C11.4543 5.99999 11.9089 5.90908 12.2725 5.81817C12.6362 5.72726 13.0907 5.81817 13.4543 5.90908C13.6362 5.90908 13.7271 5.99999 13.818 6.0909C13.9089 6.0909 13.9998 6.27272 14.0907 6.36363C14.0907 6.45454 14.0907 6.54545 14.0907 6.63636C14.0907 6.72726 14.0907 6.81817 14.0907 6.90908C14.0907 6.99999 13.9998 7.0909 13.9089 7.18181C13.9089 7.18181 13.7271 7.18181 13.6362 7.27272C13.5453 7.27272 13.3634 7.27272 13.2725 7.27272C12.9998 7.18181 12.7271 7.0909 12.4543 7.0909C12.1816 7.0909 11.9089 7.18181 11.6362 7.36363C11.3634 7.54545 11.1816 7.72726 10.9998 7.99999C10.818 8.27272 10.818 8.54545 10.818 8.81817C10.818 9.0909 10.9089 9.45454 11.0907 9.72726C11.2725 9.99999 11.4543 10.2727 11.7271 10.4545C11.9998 10.6364 12.2725 10.7273 12.5453 10.7273C12.818 10.7273 13.0907 10.7273 13.3634 10.4545C13.6362 10.2727 13.818 10.0909 13.9998 9.90908C14.1816 9.72726 14.2725 9.45454 14.2725 9.0909C14.2725 8.81817 14.4543 8.63635 14.6362 8.54545C14.7271 8.54545 14.818 8.54545 14.9089 8.54545C14.9998 8.54545 15.0907 8.54545 15.1816 8.63636C15.2725 8.63636 15.3634 8.72726 15.4543 8.90908C15.4543 8.99999 15.4543 9.0909 15.5453 9.27272C15.5453 9.45454 15.5453 9.54545 15.5453 9.72726C15.5453 10.1818 15.2725 10.5454 14.9998 10.8182C14.7271 11.1818 14.3634 11.4545 13.9998 11.7273V11.5454Z"
          fill={color}
        />
        <path
          d="M14.9089 4.63638H19.0908"
          stroke={color}
          strokeWidth="1.36364"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M16.9092 2.54544V6.72726"
          stroke={color}
          strokeWidth="1.36364"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M10 19.9091C4.54545 19.9091 0 15.4545 0 10C0 4.54545 4.54545 0 10 0C13.6364 0.454545 13.3636 0.454545 14.9091 1.27273C15.3636 1.54545 15.5455 2.09091 15.2727 2.54545C15 3 14.4545 3.18182 14 2.90909C12.8182 2.18182 11.4545 1.81818 10 1.81818C5.54545 1.90909 1.90909 5.54545 1.90909 10C1.90909 14.4545 5.54545 18.0909 10 18.0909C14.4545 18.0909 18.0909 14.4545 18.0909 10C18.0909 5.54545 18.0909 8.90909 17.9091 8.45455C17.8182 8 18.0909 7.45455 18.6364 7.36364C19.0909 7.27273 19.6364 7.54545 19.7273 8.09091C19.8182 8.72727 19.9091 9.36364 19.9091 10C19.9091 15.4545 15.4545 19.9091 10 19.9091Z"
          fill={color}
        />
      </g>
      <defs>
        <clipPath id="clip0_7897_16687">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
