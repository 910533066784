import { useCallback, useEffect, useState, useRef } from 'react';
import { useParams, useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { ROUTE_GIFT_CARDS, GIFT_CARD_ID_PARAM, ROUTE_WALLET_GIFT_CARDS } from '../../config/routes';
import { getGiftCardByUid } from '../../utils/service';
import { BUTTON_SIZE, BUTTON_TYPE, Button } from '../../global/Button';
import TermsDisclaimer from '../../global/TermsDisclaimer';
import ArrowBack from '../../images/arrow-back.svg';
import LoadingBar from '../../global/LoadingBar';
import GeneralModal from '../../global/GeneralModal';
import { getActionType, getTrackerId, POSTHOG_CAPTURE_ATTRIBUTES, TRACKER_IDS } from '../../config/tracker';
import { isMobile } from '../../../../hooks/useBreakPoint';
import {
  GiftCardCard,
  GiftCardContentSummary,
  GiftCardContentTerms,
  GiftCardCover,
  GiftCardStatusPill,
} from '../GiftCardBrand/GiftCardContent';
import { getGiftCardUniqueUrl } from '../../utils/giftCards';
import { RewardContentError } from '../Reward/RewardContent';
import GIFT_CARD_STATUS from '../../config/giftCards';

import './GiftCardInfo.scss';
import { SUPPORT_URL } from '../../config/externalURL';

const {
  giftCards: {
    detail: {
      imageLink: trackerImageLink,
      uniqueLink: trackerUniqueLink,
      codeLink: trackerCodeLink,
      shopButton: trackerShopButton,
    },
  },
} = TRACKER_IDS;

const GIFT_CARD_INFO_ID = 'giftCardInfo';

const GiftCardInfo = () => {
  const { [GIFT_CARD_ID_PARAM]: giftCardId } = useParams();
  const history = useHistory();
  const [giftCardItem, setGiftCardItem] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const timeoutRef = useRef(null);
  const mobile = isMobile();

  const toggleModal = useCallback(() => {
    setShowModal((prev) => !prev);
  }, []);

  const loadGiftCard = useCallback(async () => {
    setIsLoading(true);

    const {
      data,
      error: giftCardDataError,
    } = await getGiftCardByUid(giftCardId);

    if (giftCardDataError) {
      setGiftCardItem({});
      setError(true);
      setIsLoading(false);
      return;
    }

    setGiftCardItem(data);
    setError(false);
    setIsLoading(false);
  }, [giftCardId]);

  const uniqueLink = getGiftCardUniqueUrl(giftCardItem);

  const handleOnBack = useCallback(() => (
    history.push(ROUTE_WALLET_GIFT_CARDS)
  ), [history]);

  const scrollToTop = useCallback(() => {
    const mainElement = document.getElementById(GIFT_CARD_INFO_ID).parentElement;

    if (mainElement) {
      timeoutRef.current = setTimeout(() => {
        mainElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
      }, 0);
    }
  }, []);

  const getContent = useCallback(() => {
    if (isLoading) {
      return <LoadingBar className="GiftCardInfo__content--loader" />;
    }

    if (error) {
      return (
        <RewardContentError
          onBack={handleOnBack}
          isGiftCard
        />
      );
    }

    return (
      <>
        <GiftCardCover
          logoUrl={giftCardItem.giftCardBrand?.giftCardUrl}
          uniqueUrl={uniqueLink}
          trackerProps={{
            [POSTHOG_CAPTURE_ATTRIBUTES.TRACKER_ID]: getTrackerId(trackerImageLink),
            [POSTHOG_CAPTURE_ATTRIBUTES.ACTION_ID]: getActionType(trackerImageLink),
          }}
        />
        <div className="GiftCardInfo__content--message">
          <p className="GiftCardInfo__content--message-title">
            {`YOU SCORED A ${giftCardItem.issuedGiftCard?.amount ? `$${giftCardItem.issuedGiftCard?.amount}` : ''} GIFT CARD!`}
          </p>
          {giftCardItem.status === GIFT_CARD_STATUS.SUCCESS && (
            <p className="GiftCardInfo__content--message-desc">
              <span>You must use this </span>
              <a
                href={uniqueLink}
                {...{
                  [POSTHOG_CAPTURE_ATTRIBUTES.TRACKER_ID]: getTrackerId(trackerUniqueLink),
                  [POSTHOG_CAPTURE_ATTRIBUTES.ACTION_ID]: getActionType(trackerUniqueLink),
                }}
              >
                unique link
              </a>
              .
              {giftCardItem.issuedGiftCard?.code && (
                <>
                  <span> At checkout, use your code: </span>
                  <a
                    href={uniqueLink}
                    {...{
                      [POSTHOG_CAPTURE_ATTRIBUTES.TRACKER_ID]: getTrackerId(trackerCodeLink),
                      [POSTHOG_CAPTURE_ATTRIBUTES.ACTION_ID]: getActionType(trackerCodeLink),
                    }}
                  >
                    {giftCardItem.issuedGiftCard.code}
                  </a>
                </>
              )}
            </p>
          )}
        </div>
        {giftCardItem.status === GIFT_CARD_STATUS.SUCCESS ? (
          <>
            <a
              className="GiftCardInfo__content--cta"
              href={uniqueLink}
              {...{
                [POSTHOG_CAPTURE_ATTRIBUTES.TRACKER_ID]: getTrackerId(trackerShopButton),
                [POSTHOG_CAPTURE_ATTRIBUTES.ACTION_ID]: getActionType(trackerShopButton),
              }}
            >
              SHOP NOW
            </a>
            <div className="GiftCardInfo__content--message">
              <p className="GiftCardInfo__content--message-title">
                GIFT CARD DETAILS
              </p>
              <GiftCardCard giftCardItem={giftCardItem} />
            </div>
            <GiftCardContentSummary giftCardBrandItem={giftCardItem} />
            <TermsDisclaimer
              terms={giftCardItem.giftCardBrand?.termsCopy}
              ctaText="VIEW ALL TERMS AND INSTRUCTIONS"
              termsTitle="TERMS AND INSTRUCTIONS"
              onHandleClick={toggleModal}
            />
          </>
        ) : (
          <div className="GiftCardInfo__content--message">
            <p className="GiftCardInfo__content--message-title">
              GIFT CARD DETAILS
            </p>
            <GiftCardStatusPill status={giftCardItem.status} />
            <div className="GiftCardInfo__content--message-info">
              {(giftCardItem.status === GIFT_CARD_STATUS.ERROR && (
                <>
                  We’ve refunded your TrashieCash™ as we’ve encountered an issue while
                  processing your gift card.
                  <ul>
                    <li>
                      Please try again, 99% of the times Gift Cards get successfully processed.
                    </li>
                    <li>
                      If the issue persists, we’re here to help!
                      <a href={SUPPORT_URL}>CONTACT SUPPORT</a>
                    </li>
                  </ul>
                </>
              )) || 'It may take up to 15 minutes to be delivered.'}
            </div>
          </div>
        )}
      </>
    );
  }, [giftCardItem, isLoading, error, uniqueLink]);

  useEffect(() => {
    loadGiftCard();
    scrollToTop();

    return () => timeoutRef?.current && clearTimeout(timeoutRef.current);
  }, []);

  return (
    <div className="GiftCardInfo" id={GIFT_CARD_INFO_ID}>
      <div className="GiftCardInfo__content">
        <div className="GiftCardInfo__content--container">
          {!mobile && (
            <Button
              type={BUTTON_TYPE.SECONDARY}
              size={BUTTON_SIZE.MEDIUM}
              className="GiftCardInfo__content--button"
              onClick={handleOnBack}
            >
              <img src={ArrowBack} alt="arrow-back" />
            </Button>
          )}
          <div className="GiftCardInfo__content--section">
            {getContent()}
          </div>
        </div>
      </div>
      {(
        !isLoading &&
        !error &&
        [GIFT_CARD_STATUS.ERROR, GIFT_CARD_STATUS.PROCESSING].includes(giftCardItem.status)
      ) && (
        <div className="GiftCardInfo__ctaContainer">
          {giftCardItem.status === GIFT_CARD_STATUS.ERROR ? (
            <a
              className="cta-shop"
              href={ROUTE_GIFT_CARDS}
            >
              TRY AGAIN
            </a>
          ) : (
            <a
              className="cta-shop"
              href={uniqueLink}
              {...{
                [POSTHOG_CAPTURE_ATTRIBUTES.TRACKER_ID]: getTrackerId(trackerShopButton),
                [POSTHOG_CAPTURE_ATTRIBUTES.ACTION_ID]: getActionType(trackerShopButton),
              }}
            >
              SHOP NOW
            </a>
          )}
        </div>
      )}
      <GeneralModal
        onClose={toggleModal}
        showModal={showModal}
        showModalHeader={false}
        showModalFooter={false}
        canClose
        showClose
      >
        <GiftCardContentTerms
          giftCardBrandItem={giftCardItem.giftCardBrand}
          onClose={toggleModal}
        />
      </GeneralModal>
    </div>
  );
};

export default GiftCardInfo;
