import { BUTTON_SIZE, BUTTON_TYPE, Button } from '../../../global/Button';

import './RewardContent.scss';

const RewardContentGeneric = ({
  title,
  content,
  onClose,
}) => (
  <div className="body-style terms">
    <div className="content">
      <div className="content__title">
        {title}
      </div>
      <div className="content__terms">
        {content}
      </div>
    </div>
    {onClose && (
      <div className="bottom">
        <Button
          className="close-button"
          type={BUTTON_TYPE.SECONDARY}
          size={BUTTON_SIZE.LARGE}
          onClick={onClose}
        >
          close
        </Button>
      </div>
    )}
  </div>
);

export default RewardContentGeneric;
