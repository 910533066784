import { useEffect, useCallback, useState, useRef } from 'react';
import style from './TakeBackBags.module.scss';
import crud from '../../../../api/crud';
import ItemList from '../../global/ItemList';
import ListHeaders from '../../global/ListHeaders';
import LoadingBar from '../../global/LoadingBar';
import ListPaginator from '../../global/ListPaginator';
import { TBB_LIST_BY_USER } from '../../config/service';
import { useAlert } from '../../../providers/AlertProvider';
import { ANCHOR_MY_TBB } from '../../config/routes';
import GenericBanner from '../../global/Banner/GenericBanner';
import {
  HOW_WE_RECYCLE_URL,
  TAKE_BACK_BAG_COLLECTION_URL,
  TAKE_BACK_BAG_RAINBOW_3_PACK_URL,
  TAKE_BACK_BAG_URL,
  TECH_BACK_BOX_SMALL_URL,
  TECH_BACK_BOX_MEDIUM_URL,
} from '../../config/externalURL';
import TBBSmallBox from '../../images/tbb-small-box.webp';
import TBMediumBox from '../../images/tbb-midium-box.webp';
import TBBIphones from '../../images/tech-tbb-iphones.webp';
import TBBSingle from '../../images/tbb-single.png';
import TBB3Pack from '../../images/tbb-3-pack.png';
import DashboardNewSection from '../DashboardNew/DashboardNewSection/DashboardNewSection';
import TakeBackBagsList from '../../global/TakeBackBagsList';

const {
  main,
  container,
  listContainer,
} = style;

const PAGE_SIZE = 10;
const TBB_ITEMS = [
  {
    id: 1,
    label: 'The single',
    url: TAKE_BACK_BAG_URL,
    imgUrl: TBBSingle,
    bgColor: '#9AD2FF',
  },
  {
    id: 2,
    label: 'The 3-pack',
    url: TAKE_BACK_BAG_RAINBOW_3_PACK_URL,
    imgUrl: TBB3Pack,
    bgColor: '#D8C6FF',
  },
  {
    id: 3,
    label: 'Small Tech Take Back Box',
    url: TECH_BACK_BOX_SMALL_URL,
    imgUrl: TBBSmallBox,
    bgColor: '#FEF288',
  },
  {
    id: 4,
    label: 'Medium Tech Take Back Box',
    url: TECH_BACK_BOX_MEDIUM_URL,
    imgUrl: TBMediumBox,
    bgColor: '#ADD6FF',
  },
];

const itemListHeader = {
  title: 'My shipping labels',
  sortedText: 'Most recently registered',
};

const recycleContent = {
  title: 'How we recycle',
  description: 'Learn more about our innovative sorting and grading system that enables getting unwanted clothing and e-waste to the right place.',
  ctaLabel: 'Learn more',
  ctaURL: HOW_WE_RECYCLE_URL,
  srcImage: TBBIphones,
};

function parseTbbData(redemption) {
  const { labeldata, recyclingProduct, ...rest } = redemption;
  let parsedRedemption = {
    recyclingProductTypeId: recyclingProduct.recyclingProductTypeId,
    ...rest,
  };

  if (labeldata) {
    parsedRedemption = {
      ...parsedRedemption,
      labeldata: JSON.parse(labeldata),
    };
  }

  return parsedRedemption;
}

const TakeBackBags = () => {
  const timeoutRef = useRef(null);

  const [tbbData, setTbbData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [totalRecords, setTotalRecords] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const setAlert = useAlert();

  const userTbb = useCallback(
    async () => {
      setLoading(true);
      try {
        const { count, result } = await crud.get({ path: `${TBB_LIST_BY_USER}?page=${currentPage}&pageSize=${PAGE_SIZE}` });
        const data = result.map((redemption) => parseTbbData(redemption));
        setTbbData(data);
        setTotalRecords(count);
      } catch (error) {
        setAlert({
          type: 'alert',
          message: error.error.message,
          error: error.error.message,
        });
      }
      setLoading(false);
      const mainElement = document.getElementById(ANCHOR_MY_TBB);

      if (mainElement) {
        timeoutRef.current = setTimeout(() => {
          mainElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }, 0);
      }
    },
    [currentPage],
  );

  useEffect(() => {
    userTbb();
  }, [currentPage]);

  return (
    <div id={ANCHOR_MY_TBB} className={main}>
      <div className={container}>
        <DashboardNewSection
          title="Shop the TBB"
          onClick={() => { window.location.href = TAKE_BACK_BAG_COLLECTION_URL; }}
          contained
        >
          <TakeBackBagsList tbbList={TBB_ITEMS} />
        </DashboardNewSection>
        {loading && <LoadingBar />}
        {!loading && tbbData.length > 0 && (
          <>
            <ListHeaders
              title={itemListHeader.title}
              sortedText={itemListHeader.sortedText}
            />
            <div className={listContainer}>
              <ItemList dataList={tbbData} setTbbData={setTbbData} />
              {totalRecords > PAGE_SIZE && (
                <ListPaginator
                  pageSize={PAGE_SIZE}
                  totalItems={totalRecords}
                  currentPage={currentPage}
                  onPageSelection={(selectedPage) => {
                    setCurrentPage(selectedPage);
                  }}
                />
              )}
            </div>
          </>
        )}
        <GenericBanner
          content={recycleContent}
        />
      </div>
    </div>

  );
};

export default TakeBackBags;
