import tCoinLogo from '../../../images/t-coin-logo.svg';

import './TakeBackBagsListItem.scss';

const TakeBackBagsListItem = ({
  tbbItem: {
    label,
    ctaLabel,
    imgUrl,
    url,
    bgColor,
  } = {},
}) => (
  <a
    className="TakeBackBagsListItem"
    href={url}
  >
    <div
      className="TakeBackBagsListItem__imgContainer"
      style={{
        backgroundImage: `url(${imgUrl || tCoinLogo})`,
        backgroundColor: bgColor,
      }}
    />
    <div className="TakeBackBagsListItem__details">
      <div
        className="TakeBackBagsListItem__details--label"
        title={label}
      >
        {label}
      </div>
      <div className="TakeBackBagsListItem__details--activateButton">
        {ctaLabel || 'BUY NOW'}
      </div>
    </div>
  </a>
);

export default TakeBackBagsListItem;
