import { useCallback, useMemo, useState } from 'react';
import { formatMMDDYY } from '../../../utils/dates';
import HourGlassDoneIcon from '../../../images/hourglass-done-icon.svg';
import NewCircledIcon from '../../../images/new-circled-icon.svg';
import PersonNoIcon from '../../../images/person-no-icon.svg';
import TrollIcon from '../../../images/troll-icon.svg';
import WarningIcon from '../../../images/warning-icon.svg';
import HeavyDollar from '../../../images/heavy-dollar-sign.svg';
import TrashieCoin from '../../../images/TC-icon-black.svg';
import IterationCircleIcon from '../../../images/iteration-circle-icon.svg';
import { REWARD_AMOUNT_TYPES_ID } from '../../../config/rewards';
import GeneralModal from '../../../global/GeneralModal';
import RewardContentGeneric from './RewardContentGeneric';

const RewardSummary = ({
  rewardItem,
  title,
}) => {
  const [showModal, setShowModal] = useState(false);

  const {
    exchangeValue,
    expireAt,
    forFirstTimeCustomerOnly,
    description,
    isValidOnDiscountedItems,
    isStackable,
    store: {
      title: storeTitle,
      tcCreditAmount = '0',
      tcCreditAmountType = REWARD_AMOUNT_TYPES_ID.VALUE,
    },
    allowTcCredits,
  } = rewardItem;

  const toggleModal = useCallback(() => {
    setShowModal((prev) => !prev);
  }, []);

  const summaryItems = useMemo(() => [
    Boolean(allowTcCredits && Number(tcCreditAmount) > 0) && ({
      id: 'trashiecash',
      icon: IterationCircleIcon,
      label: (
        <>
          <span>NEW</span>
          <button
            type="button"
            onClick={toggleModal}
          >
            {`Get ${tcCreditAmount}${tcCreditAmountType === REWARD_AMOUNT_TYPES_ID.PERCENTAGE ? '%' : ''} TrashieCash™ back`}
          </button>
          {`when shopping with this ${Number(exchangeValue) === 0 ? 'deal' : 'reward'} at ${storeTitle}. TrashieCash™ payouts arrive 7–14 days after your purchase.`}
        </>
      ),
      enabled: true,
    }),
    {
      id: 'rewardDescription',
      icon: HeavyDollar,
      label: description,
      enabled: true,
    },
    {
      id: 'expires',
      icon: HourGlassDoneIcon,
      label: `Expires on: ${formatMMDDYY(expireAt)}`,
      enabled: Boolean(expireAt),
    },
    exchangeValue > 0 && ({
      id: 'price',
      icon: TrashieCoin,
      label: `Get it for ${`${exchangeValue} TrashieCash™`}`,
      enabled: true,
    }),
    {
      id: 'firstTime',
      icon: NewCircledIcon,
      label: 'First time customers only',
      enabled: forFirstTimeCustomerOnly,
    },
    {
      id: 'saleExcluded',
      icon: TrollIcon,
      label: 'Offer excludes discounted and sale items',
      enabled: !isValidOnDiscountedItems,
    },
    {
      id: 'notStackable',
      icon: PersonNoIcon,
      label: 'Cannot be combined with other discounts',
      enabled: !isStackable,
    },
    {
      id: 'nonRefundable',
      icon: WarningIcon,
      label: 'Non-refundable',
      enabled: true,
    },
  ], [
    description,
    exchangeValue,
    forFirstTimeCustomerOnly,
    isValidOnDiscountedItems,
    isStackable,
    expireAt,
  ]);

  return (
    <div className="summary">
      {title && (
        <p className="title">{title}</p>
      )}
      {summaryItems.map(({
        id,
        icon,
        label,
        enabled,
      }) => (
        enabled ? (
          <div
            key={id}
            className="message"
          >
            <div className="icon-container">
              <img src={icon} alt={`${id} icon`} />
            </div>
            <p className="label-container">{label}</p>
          </div>
        ) : null
      ))}
      <GeneralModal
        onClose={toggleModal}
        showModal={showModal}
        showModalHeader={false}
        showModalFooter={false}
        canClose
        showClose
      >
        <RewardContentGeneric
          title={`EARN TRASHIECASH™ BACK WHEN SHOPPING WITH ${Number(exchangeValue) === 0 ? 'DEALS' : 'REWARDS'}`}
          content={(
            <>
              <p>
                To earn TrashieCash™ back, you must click on the shopping links provided by Trashie,
                which can be found in the app or email communications.
              </p>
              <p>
                TrashieCash™ payouts typically occur 7–14 days after the transaction is completed,
                though some might take up to 30 days or more to process.
              </p>
              <p>You’ll be able to see them reflected in your Transaction History.</p>
            </>
          )}
          onClose={toggleModal}
        />
      </GeneralModal>
    </div>
  );
};

export default RewardSummary;
