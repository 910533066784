import { useMemo } from 'react';
import classNames from 'classnames';
import { useLocation } from 'react-router-dom';

import { REWARD_AMOUNT_TYPES } from '../../../config/rewards';
import { POSTHOG_CAPTURE_ATTRIBUTES,
  MODIFIERS,
  TRACKER_IDS,
  getTrackerId,
  getActionType,
  getConditionalModifier } from '../../../config/tracker';
import tCoinLogo from '../../../images/t-coin-logo.svg';
import { getUniqueLink, isLimitErrorCode, isUserLimitErrorCode } from '../../../utils/rewards';
import Flag from '../Flag';
import Card, { CARD_SIZE, CARD_TYPE } from '../Card';
import { getSearchParam } from '../../../utils/routes';
import { WF_RETURN_URL_SEARCH_PARAM } from '../../../config/routes';

import './Cover.scss';

export const COVER_TYPE = {
  LIST: 'list',
  REDEEM: 'redeem',
  FINAL: 'final',
};

const EXCHANGE_VALUE_FLAGGED = 1;

const {
  myRewards: {
    detail: {
      imageLink: trackerImageLink,
    },
  },
} = TRACKER_IDS;

const Cover = ({
  rewardItem,
  type = COVER_TYPE.LIST,
  backgroundEnabled = true,
  rewardProperties,
}) => {
  const {
    uid,
    amountType,
    rewardAmount,
    exchangeValue,
    status,
    description,
    rewardCodes,
    type: rewardType,
    store: {
      logoUrl,
      headerLogoProperties,
      imgUrl,
      cardFontColor,
      cardBgColor,
    },
  } = rewardItem;

  const { search } = useLocation();
  const query = new URLSearchParams(search);
  const disabled = isLimitErrorCode(status) || isUserLimitErrorCode(status);
  const cardDetails = type === COVER_TYPE.LIST;
  const exchangeValueNumber = Number(exchangeValue);
  const rewardAmountType = REWARD_AMOUNT_TYPES[amountType] ?? {};
  const wfReturnUrl = getSearchParam(query, WF_RETURN_URL_SEARCH_PARAM);

  const uniqueLink = useMemo(() => (
    type === COVER_TYPE.FINAL ? (wfReturnUrl || getUniqueLink(rewardItem)) : ''
  ), [type, rewardItem]);

  return (
    <div
      key={`reward-cover-${uid}`}
      className={classNames('RewardCover', type)}
    >
      {(
        disabled ||
        (type === COVER_TYPE.LIST && exchangeValueNumber <= EXCHANGE_VALUE_FLAGGED)
      ) && (
        <Flag
          status={status}
          hasRewardCodes={rewardCodes.length > 0}
          exchangeValue={exchangeValue}
          properties={rewardProperties}
        />
      )}
      {uniqueLink ? (
        <a
          href={uniqueLink}
          aria-label="store image url"
          {...{
            [POSTHOG_CAPTURE_ATTRIBUTES.TRACKER_ID]: getTrackerId(trackerImageLink),
            [POSTHOG_CAPTURE_ATTRIBUTES.ACTION_ID]: getActionType(trackerImageLink),
            ...getConditionalModifier(
              POSTHOG_CAPTURE_ATTRIBUTES.MODIFIER_ID,
              MODIFIERS.WF_LINK,
              wfReturnUrl,
            ),
          }}
        >
          <ImgContainer
            backgroundEnabled={backgroundEnabled}
            disabled={disabled}
            imgUrl={imgUrl}
          />
        </a>
      ) : (
        <ImgContainer
          backgroundEnabled={backgroundEnabled}
          disabled={disabled}
          imgUrl={imgUrl}
        />
      )}
      <div className="RewardCover__card">
        <Card
          logoUrl={logoUrl}
          logoStyle={headerLogoProperties}
          backgroundColor={cardBgColor}
          size={type === COVER_TYPE.LIST ? CARD_SIZE.SMALL : CARD_SIZE.LARGE}
          type={cardDetails ? CARD_TYPE.DETAILS : CARD_TYPE.DEFAULT}
          value={cardDetails ? (
            rewardAmountType.getText({ rewardAmount, type: rewardType }) ?? undefined
          ) : undefined}
          info={cardDetails ? description : undefined}
          color={cardDetails ? cardFontColor : undefined}
          cardLink={uniqueLink}
          height={cardDetails ? undefined : '180px'}
        />
      </div>
    </div>
  );
};

const ImgContainer = ({
  backgroundEnabled,
  disabled,
  imgUrl,
}) => (
  <div className={backgroundEnabled ? 'RewardCover__imgContainer' : 'RewardCover__imgContainer--disabled'}>
    {backgroundEnabled && (
      <img
        className={classNames({ disabled })}
        src={imgUrl || tCoinLogo}
        alt="merchant logo"
      />
    )}
  </div>
);

export default Cover;
