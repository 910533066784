import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Link } from 'react-router-dom';

import {
  ROUTE_GIFT_CARD_INFO,
  GIFT_CARD_ID_ROUTE_PARAM,
  ROUTE_GIFT_CARDS_BRAND_ID,
  BRAND_ID_ROUTE_PARAM,
} from '../../../config/routes';
import GIFT_CARD_STATUS from '../../../config/giftCards';
import { getActionType, getTrackerId, POSTHOG_CAPTURE_ATTRIBUTES, TRACKER_IDS } from '../../../config/tracker';
import { formatMMDDYY } from '../../../utils/dates';
import CopyButton from '../../CopyButton';
import { CARD_TYPES, GiftCardCover, GiftCardStatusPill } from '../../../views/GiftCardBrand/GiftCardContent';
import { getGiftCardUniqueUrl } from '../../../utils/giftCards';
import { isMobile } from '../../../../../hooks/useBreakPoint';

import './MyGiftCardItem.scss';

const {
  myGiftCards: {
    list: {
      cardLink: trackerCardLink,
      titleLink: trackerTitleLink,
      copyCodeLink: trackerCopyCodeLink,
      copyPinLink: trackerCopyPinLink,
      shopButton: trackerShopButton,
      codeLink: trackerCodeLink,
    },
  },
} = TRACKER_IDS;

const COPY_DATA_TYPES = {
  CODE: 'code',
  PIN: 'pin',
};

const MyGiftCardItem = ({
  giftCardItem,
  isCopied,
  onCopy,
}) => {
  const timeoutRef = useRef(null);
  const isMobileSize = isMobile();
  const [copiedData, setCopiedData] = useState(null);

  const {
    uid,
    redeemedAt,
    status,
    giftCardBrand: {
      uid: brandId,
      name: brandName,
    },
    issuedGiftCard,
  } = useMemo(() => giftCardItem, [giftCardItem]);

  const {
    code,
    pin,
    amount,
  } = useMemo(() => issuedGiftCard ?? {}, [issuedGiftCard]);

  const description = useMemo(() => (
    `${amount ? `$${amount} ` : ''}gift card to ${brandName}`
  ), [amount, brandName]);

  const uniqueLink = getGiftCardUniqueUrl(giftCardItem);

  const handleCopy = useCallback((dataType) => {
    const data = dataType === COPY_DATA_TYPES.PIN ? pin : code;

    setCopiedData(dataType);
    onCopy(uid);

    navigator.clipboard.writeText(data);

    timeoutRef.current = setTimeout(() => {
      window.location.href = uniqueLink;
    }, 500);
  }, [code, pin, uniqueLink]);

  useEffect(() => {
    if (!isCopied) {
      setCopiedData(null);
    }
  }, [isCopied]);

  useEffect(() => (
    () => timeoutRef?.current && clearTimeout(timeoutRef.current)
  ), []);

  return (
    <div
      role="listitem"
      className="MyGiftCardItem"
    >
      <GiftCardCover
        logoUrl={giftCardItem.giftCardBrand?.giftCardUrl}
        uniqueUrl={uniqueLink}
        type={CARD_TYPES.SMALL}
        trackerProps={{
          [POSTHOG_CAPTURE_ATTRIBUTES.TRACKER_ID]: getTrackerId(trackerCardLink),
          [POSTHOG_CAPTURE_ATTRIBUTES.ACTION_ID]: getActionType(trackerCardLink),
        }}
      />
      {isMobileSize && (
        <div className="MyGiftCardItem__content content-mobile">
          <a
            className="MyGiftCardItem__content--title"
            href={uniqueLink}
            {...{
              [POSTHOG_CAPTURE_ATTRIBUTES.TRACKER_ID]: getTrackerId(trackerTitleLink),
              [POSTHOG_CAPTURE_ATTRIBUTES.ACTION_ID]: getActionType(trackerTitleLink),
            }}
          >
            {brandName}
          </a>
          <div className="MyGiftCardItem__content--details">
            <span className="details-small">{description}</span>
          </div>
        </div>
      )}
      <div className="MyGiftCardItem__content">
        {!isMobileSize && (
          <a
            className="MyGiftCardItem__content--title"
            href={uniqueLink}
            {...{
              [POSTHOG_CAPTURE_ATTRIBUTES.TRACKER_ID]: getTrackerId(trackerTitleLink),
              [POSTHOG_CAPTURE_ATTRIBUTES.ACTION_ID]: getActionType(trackerTitleLink),
            }}
          >
            {brandName}
          </a>
        )}
        {!isMobileSize && (
          <div className="MyGiftCardItem__content--details">
            <span className="details-small">{description}</span>
          </div>
        )}
        {status === GIFT_CARD_STATUS.SUCCESS ? (
          <>
            <div className="MyGiftCardItem__content--details details-highlight">
              <span className="details-label">
                {code ? 'CODE' : 'MY GIFT CARD'}
              </span>
              <CopyButton
                className="details-highlight"
                data={code || (
                  <a
                    className="GiftCardCard__item--link"
                    href={uniqueLink}
                    {...{
                      [POSTHOG_CAPTURE_ATTRIBUTES.TRACKER_ID]: getTrackerId(trackerCodeLink),
                      [POSTHOG_CAPTURE_ATTRIBUTES.ACTION_ID]: getActionType(trackerCodeLink),
                    }}
                  >
                    CLICK TO USE MY GIFT CARD
                  </a>
                )}
                onClick={code ? () => handleCopy(COPY_DATA_TYPES.CODE) : undefined}
                copied={copiedData === COPY_DATA_TYPES.CODE}
                trackerProps={{
                  [POSTHOG_CAPTURE_ATTRIBUTES.TRACKER_ID]: getTrackerId(trackerCopyCodeLink),
                  [POSTHOG_CAPTURE_ATTRIBUTES.ACTION_ID]: getActionType(trackerCopyCodeLink),
                }}
              />
            </div>
            {pin && (
              <div className="MyGiftCardItem__content--details details-highlight">
                <span className="details-label">PIN:</span>
                <CopyButton
                  className="details-highlight"
                  data={pin}
                  onClick={() => handleCopy(COPY_DATA_TYPES.PIN)}
                  copied={copiedData === COPY_DATA_TYPES.PIN}
                  trackerProps={{
                    [POSTHOG_CAPTURE_ATTRIBUTES.TRACKER_ID]: getTrackerId(trackerCopyPinLink),
                    [POSTHOG_CAPTURE_ATTRIBUTES.ACTION_ID]: getActionType(trackerCopyPinLink),
                  }}
                />
              </div>
            )}
            <div className="MyGiftCardItem__content--details">
              <span className="details-label details-small">Purchased:</span>
              <span className="details-small">{formatMMDDYY(redeemedAt)}</span>
            </div>
            <Link
              to={ROUTE_GIFT_CARD_INFO.replace(GIFT_CARD_ID_ROUTE_PARAM, uid)}
              className="MyGiftCardItem__content--link"
            >
              GIFT CARD DETAILS
            </Link>
          </>
        ) : (
          <>
            <GiftCardStatusPill status={status} />
            <div className="MyGiftCardItem__content-info">
              {status === GIFT_CARD_STATUS.ERROR ? (
                'We’ve refunded your TrashieCash™ as we’ve encountered an issue while processing your gift card. Please try again.'
              ) : (
                'It may take up to 15 minutes to be delivered.'
              )}
            </div>
          </>
        )}
      </div>
      {[GIFT_CARD_STATUS.ERROR, GIFT_CARD_STATUS.SUCCESS].includes(status) && (
        status === GIFT_CARD_STATUS.ERROR ? (
          <a
            className="MyGiftCardItem__cta"
            href={ROUTE_GIFT_CARDS_BRAND_ID.replace(BRAND_ID_ROUTE_PARAM, brandId)}
          >
            TRY AGAIN
          </a>
        ) : (
          <a
            className="MyGiftCardItem__cta"
            href={uniqueLink}
            {...{
              [POSTHOG_CAPTURE_ATTRIBUTES.TRACKER_ID]: getTrackerId(trackerShopButton),
              [POSTHOG_CAPTURE_ATTRIBUTES.ACTION_ID]: getActionType(trackerShopButton),
            }}
          >
            SHOP NOW
          </a>
        )
      )}
    </div>
  );
};

export default MyGiftCardItem;
