import { useCallback, useEffect, useState } from 'react';
import { usePostHog } from 'posthog-js/react';

import { useFirebaseAuth } from '../components/global/FirebaseProvider/FirebaseProvider';
import { getConfigurations } from '../components/trashie/utils/service';
import { POSTHOG_PROPERTIES, TRACKER_EVENT_TYPES } from '../components/trashie/config/tracker';

const useHasAccessToGiftCards = () => {
  const { userData, isLoading: isLoadingFirebaseAuth, isSignedIn } = useFirebaseAuth();
  const posthog = usePostHog();

  const [isLoadingGiftCardsSegments, setIsLoadingGiftCardsSegments] = useState(true);
  const [giftCardsSegments, setGiftCardsSegments] = useState({});
  const [posthogSent, setPosthogSent] = useState(false);

  const loadGiftCardsSegments = useCallback(async () => {
    setIsLoadingGiftCardsSegments(true);
    const { value, error } = await getConfigurations('giftCardsSegments');

    if (error) {
      setGiftCardsSegments({});
      setIsLoadingGiftCardsSegments(false);
      return;
    }

    setGiftCardsSegments(value);
    setIsLoadingGiftCardsSegments(false);
  }, []);

  const getHasAccessToGiftCards = useCallback(() => {
    const userSegments = userData?.segments || [];
    const {
      enabled: segmentsEnabled,
      segments,
    } = giftCardsSegments;

    if (!segmentsEnabled) {
      return true;
    }

    const segmentFound = segments.find(segment => userSegments.includes(segment));

    if (segmentFound && !posthogSent) {
      posthog.capture(
        TRACKER_EVENT_TYPES.GIFT_CARDS_SEGMENT_SET,
        {
          [POSTHOG_PROPERTIES.GIFT_CARDS_SEGMENT]: segmentFound,
          $set: {
            email: userData.email,
          },
        },
      );

      setPosthogSent(true);
    }

    return Boolean(segmentFound);
  }, [userData, giftCardsSegments, posthogSent]);

  useEffect(() => {
    if (!isLoadingFirebaseAuth && isSignedIn) {
      loadGiftCardsSegments();
    }
  }, [isLoadingFirebaseAuth, isSignedIn]);

  return {
    isLoading: isLoadingFirebaseAuth || isLoadingGiftCardsSegments,
    hasAccessToGiftCards: getHasAccessToGiftCards(),
  };
};

export default useHasAccessToGiftCards;
