const SvgComponent = ({ stroke = '#6F706F' }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={16}
    height={16}
    fill="none"
  >
    <g
      stroke={stroke}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.333}
    >
      <path d="M5.333 15.167a.667.667 0 1 0 0-1.334.667.667 0 0 0 0 1.334ZM12.667 15.167a.667.667 0 1 0 0-1.334.667.667 0 0 0 0 1.334ZM1.367 1.867H2.7l1.773 8.28A1.334 1.334 0 0 0 5.807 11.2h6.52a1.333 1.333 0 0 0 1.3-1.047l1.1-4.953H3.413" />
    </g>
  </svg>
);
export default SvgComponent;
