import { useCallback, useMemo } from 'react';
import { useHistory } from 'react-router-dom';

import { ROUTE_GIFT_CARDS } from '../../../config/routes';
import { TAKE_BACK_BAG_URL } from '../../../config/externalURL';
import { getGiftCardBrandDenominationOptions, getGiftCardDenominationsMessage, getGiftCardRatio } from '../../../utils/giftCards';
import { BUTTON_SIZE, BUTTON_TYPE, Button } from '../../../global/Button';
import TermsDisclaimer from '../../../global/TermsDisclaimer/TermsDisclaimer';
import GiftCardContentSummary from './GiftCardContentSummary';
import SliderSelector from '../../Reward/SliderSelector';
import TCCoin from '../../../images/TC-icon.svg';
import GiftCardCover from './GiftCardCover';

import './GiftCardContent.scss';

const GiftCardContentMain = ({
  giftCardBrandItem,
  selectedValue,
  onSelect,
  onSubmit,
  onShowTerms,
  currentBalance,
  disabled,
  submitDisabled,
  minBalance,
  minDenomination,
  trackerProps = {},
}) => {
  const history = useHistory();

  const {
    ratio,
    options,
    message,
  } = useMemo(() => ({
    ratio: getGiftCardRatio(giftCardBrandItem.tcRatio),
    options: getGiftCardBrandDenominationOptions(giftCardBrandItem.denominations),
    message: getGiftCardDenominationsMessage(giftCardBrandItem.denominations),
  }), [giftCardBrandItem]);

  const handleSubmit = useCallback(() => {
    if (submitDisabled) {
      history.push(ROUTE_GIFT_CARDS);
      return;
    }

    onSubmit?.();
  }, [history, onSubmit, submitDisabled]);

  return (
    <div className="GiftCardContentMain">
      <GiftCardCover logoUrl={giftCardBrandItem.giftCardUrl} />
      <div className="GiftCardContentMain__content">
        <div className="GiftCardContentMain__content-action">
          {disabled ? (
            <div className="GiftCardContentMain__content-action-message">
              <p className="GiftCardContentMain__content-action-message-title">
                <a href={TAKE_BACK_BAG_URL}>EARN MORE </a>
                TRASHIECASH™ TO UNLOCK GIFT CARD
              </p>
              <p className="GiftCardContentMain__content-action-message-desc">
                <span>{`You need at least ${minBalance} `}</span>
                <img src={TCCoin} alt="TCH" />
                <span>{` to get a $${minDenomination} ${giftCardBrandItem.name} Gift Card.`}</span>
              </p>
            </div>
          ) : (
            <SliderSelector
              value={selectedValue}
              title="GIFT CARD AMOUNT"
              message={message}
              options={options}
              onChange={onSelect}
              currentBalance={currentBalance}
              ratio={ratio}
            />
          )}
          <Button
            className="GiftCardContentMain__content-action-button"
            type={submitDisabled ? BUTTON_TYPE.SECONDARY : BUTTON_TYPE.PRIMARY}
            size={BUTTON_SIZE.LARGE}
            onClick={handleSubmit}
            trackerProps={submitDisabled ? {} : trackerProps}
          >
            {submitDisabled ? 'BROWSE ALL GIFT CARDS' : 'GET THIS GIFT CARD'}
          </Button>
        </div>
        <GiftCardContentSummary
          giftCardBrandItem={giftCardBrandItem}
          title="GIFT CARD DETAILS"
        />
        <TermsDisclaimer
          terms={giftCardBrandItem.termsCopy}
          ctaText="VIEW ALL TERMS AND INSTRUCTIONS"
          termsTitle="TERMS AND INSTRUCTIONS"
          onHandleClick={onShowTerms}
        />
      </div>
    </div>
  );
};

export default GiftCardContentMain;
