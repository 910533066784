import { useState, useCallback } from 'react';
import FormControlLabel from '../../../../global/Mui/FormControlLabel';
import Checkbox from '../../../../global/Mui/Checkbox';
import { Button, BUTTON_SIZE, BUTTON_TYPE } from '../../../../global/Button';
import { RECYCLING_BOX_VIDEO_URL, RECYCLING_BOX_STICKERS_URL } from '../../../../config/externalURL';

import './StickersAdvice.scss';

const StickersAdvice = ({
  onSubmit,
  stepTrackerProps,
}) => {
  const [isChecked, setIsChecked] = useState(false);

  const handleCheckboxChange = useCallback((event) => {
    setIsChecked(event.target.checked);
  }, [isChecked]);

  return (
    <div className="StickersAdvice">
      <div className="StickersAdvice__container">
        <video autoPlay loop className="StickersAdvice__container--video">
          <source src={RECYCLING_BOX_VIDEO_URL} type="video/mp4" />
          <track
            kind="captions"
            srcLang="en"
            label="English captions"
          />
          Your browser does not support the video tag.
        </video>
        <div className="StickersAdvice__container--title">
          Required: Add stickers to the box
        </div>
        <div className="StickersAdvice__container--content">
          <div className="StickersAdvice__container--content--text">
            <ol>
              <li>Find the stickers in your shipment.</li>
              <li>Add them to the box.</li>
              <li>Tick the checkbox below.</li>
            </ol>
          </div>
          <div className="StickersAdvice__container--content--actions">
            <FormControlLabel
              label="I’ve added the stickers to the box"
              control={(
                <Checkbox
                  checkedColor="#2649D4 !important"
                  onChange={handleCheckboxChange}
                />
              )}
            />
            <Button
              type={BUTTON_TYPE.QUATERNARY}
              size={BUTTON_SIZE.LARGE}
              onClick={onSubmit}
              disabled={!isChecked}
              trackerProps={stepTrackerProps}
            >
              CONTINUE
            </Button>
          </div>
          <div className="StickersAdvice__container--content--advice">
            Stickers lost?
            <a
              className="StickersAdvice__container--content--advice--link"
              href={RECYCLING_BOX_STICKERS_URL}
              target="_blank"
              rel="noreferrer"
            >
              download and print
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default StickersAdvice;
