import { useMemo } from 'react';
import Markdown from 'react-markdown';

import { BUTTON_SIZE, BUTTON_TYPE, Button } from '../../../global/Button';

import './GiftCardContent.scss';

const GiftCardContentTerms = ({
  giftCardBrandItem,
  onClose,
}) => {
  const {
    name,
    termsCopy,
  } = useMemo(() => (
    giftCardBrandItem
  ), [giftCardBrandItem]);

  return (
    <div className="GiftCardContentTerms">
      <div className="GiftCardContentTerms__content">
        <div className="GiftCardContentTerms__content-title">{`${name} TERMS AND INSTRUCTIONS`}</div>
        <Markdown className="GiftCardContentTerms__content-markdown">
          {termsCopy}
        </Markdown>
      </div>
      <div className="GiftCardContentTerms__bottom">
        <Button
          className="GiftCardContentTerms__bottom-button"
          type={BUTTON_TYPE.SECONDARY}
          size={BUTTON_SIZE.LARGE}
          onClick={onClose}
        >
          Close
        </Button>
      </div>
    </div>
  );
};

export default GiftCardContentTerms;
